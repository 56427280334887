import React from "react";
import { useConfig } from "../../hooks/useConfig";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Select,
  Space,
  Tag,
  DatePicker,
  Radio,
} from "antd";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMobile } from "../../hooks";
import { useState, useEffect, useRef } from "react";
import "./ReplyStyle.scss";

import { fetchData, putData } from "../../endpoints";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import AddComment from "./comment/addComment";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;

function Appointments() {
  const [form] = Form.useForm();
  const isMobile = useMobile();
  const [data_appointment, setdata_appointment] = useState([]);
  const [addnotemodal, setaddnotemodal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingUpcoming, setisLoadingUpcoming] = useState(false);
  const [dataSource2, setdataSource2] = useState([]);
  const [selectedappointment, setselectedappointment] = useState({});
  const [isAppointment, setIsAppointment] = useState(false);
  const [editappointmentmodalopen, seteditappointmentmodalopen] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [SearchUserModal, setSearchUserModal] = useState(false);
  const [SearchappointmentModal, setSearchappointmentModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selected, setSelected] = useState({});
  const searchInput = useRef(null);
  const { getUserConfig } = useConfig();
  const [config, setConfig] = useState([]);
  const [meMode, setMeMode] = useState(false);
  const [calendarEvent, setCalandarEvents] = useState([]);

  const localizer = momentLocalizer(moment);

  const getMeMode = async () => {
    var list_of_patient = Cookies.get("assinees_patient")
      .split(",")
      .map((p) => {
        if (p) return "&patientId[]=" + parseInt(p);
      })
      .toString()
      .replace(",", "");

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay() > 2 ? date.getDay() - 1 : 29;
    var res2 = await fetchData(
      `admin/appointment?deletedAt=false&startedAtGte=${year}-${month}-${day}&sortKey=startedAt&${list_of_patient}&includes[]=patient&includes[]=event&includes[]=approvedBy&sortValue=1`
    );

    if (res2.status == 200) {
      setdataSource2(res2.data.data);
    }
  };

  const getConfig = async () => {
    getUserConfig();
    var res = JSON.parse(localStorage.getItem("config"));
    setConfig(res.data.data);
  };
  const getColumnSearchProps_upcoming = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <DatePicker.RangePicker
          style={{ marginBottom: 8, display: "block" }}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          onPressEnter={() => {
            handleSearch_upcoming(selectedKeys, confirm, dataIndex);
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch_upcoming(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              let date = new Date();

              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              if (month < 10) month = "0" + month;
              let day = date.getDate();
              if (day < 10) day = "0" + day;
              setisLoadingUpcoming(true);

              var res = await fetchData(
                `admin/appointment?deletedAt=false&startedAtGte=${year}-${month}-${day}T00:00:00.001Z&startedAtLte=${year}-${month}-${day}T23:59:59.999Z&includes[]=patient&sortKey=startedAt&includes[]=event&includes[]=approvedBy&sortValue=1&perPage=100`
              );
              if (res.status == 200) {
                setdataSource2(res.data.data);
              }
              setisLoadingUpcoming(false);
            }}
            // icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Today
          </Button>
          <Button
            onClick={() => clearFilters && handleReset_upcoming(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearch_upcoming = async (selectedKeys, confirm, dataIndex) => {
    setisLoadingUpcoming(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setdataSource2([]);
      await getupcoming(1, true);
    } else {
      let year = selectedKeys[0][0].$y;
      let month = selectedKeys[0][0].$M + 1;
      let day = selectedKeys[0][0].$D;
      let year2 = selectedKeys[0][1].$y;
      let month2 = selectedKeys[0][1].$M + 1;
      let day2 = selectedKeys[0][1].$D;

      var res1 = await fetchData(
        `admin/appointment/?deletedAt=false&${dataIndex}Gte=${year}-${month}-${day}&${dataIndex}Lte=${year2}-${month2}-${day2}&sortKey=startedAt&includes[]=patient&&includes[]=event&includes[]=approvedBy&sortValue=1&perPage=100&page=1`
      );

      if (res1.status == 200) {
        res1.data.data.map((tempdata, index) => {
          tempdata["key"] = index;
        });
        setdataSource2(res1.data.data);
      }
    }
    setisLoadingUpcoming(false);
  };
  const handleReset_upcoming = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdataSource2([]);
    await getupcoming(1, true);
  };
  const checked = (u) =>
    Modal.confirm({
      title: "Are you sure you want to check this appointment?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        checkeWith(u);
      },
      onCancel() {},
    });
  const checkeWith = async (u) => {
    setisLoadingUpcoming(true);
    var date = new Date();
    var res = await putData(`admin/appointment/${u.id}`, {
      approvedAt: date,
    });
    if (res.status == 200) {
      Modal.success({
        title: "Appointment checked successfully",
      });
    } else {
      Modal.error({
        title: res,
      });
    }
    await getupcoming(1, true);
    setisLoadingUpcoming(false);
  };

  //for search modal
  const okPatientModal = async (id = null) => {
    setisLoadingUpcoming(true);
    if (isAppointment) {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDay() > 2 ? date.getDay() - 1 : 29;
      var res;
      if (id) {
        res = await fetchData(
          `admin/appointment?deletedAt=false&startedAtGte=${year}-${month}-${day}&sortKey=startedAt&patientId=${id}&includes[]=patient&includes[]=event&includes[]=approvedBy&sortValue=1`
        );
      } else
        res = await fetchData(
          `admin/appointment?deletedAt=false&startedAtGte=${year}-${month}-${day}&sortKey=startedAt&patientId=${data[selectedRowKeys].id}&includes[]=patient&includes[]=approvedBy&includes[]=event&sortValue=1`
        );
      setdataSource2(res.data.data);
    } else {
      var res;
      if (id) {
        res = await fetchData(
          `admin/purchase-step?includes[]=serviceStep&status=need_admin_approve&includes[]=patient&patientId=${id}`
        );
      } else
        res = await fetchData(
          `admin/purchase-step?includes[]=serviceStep&status=need_admin_approve&includes[]=patient&patientId=${data[selectedRowKeys].id}`
        );
    }
    setSearchUserModal(false);
    setSelectedRowKeys(false);
    setisLoadingUpcoming(false);
  };
  const okAppintmentModal = async (id = null) => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay() > 2 ? date.getDay() - 1 : 29;
    setisLoadingUpcoming(true);
    var res;
    if (id) {
      res = await fetchData(
        `admin/appointment?deletedAt=false&startedAtGte=${year}-${month}-${day}&sortKey=startedAt&eventId=${id}&includes[]=patient&includes[]=event&includes[]=approvedBy&sortValue=1&perpage=100&page=1`
      );
    } else
      res = await fetchData(
        `admin/appointment?deletedAt=false&startedAtGte=${year}-${month}-${day}&sortKey=startedAt&eventId=${data_appointment[selectedRowKeys].id}&includes[]=patient&includes[]=event&includes[]=approvedBy&sortValue=1&page=1&perPage=100`
      );
    setdataSource2(res.data.data);
    setSearchappointmentModal(false);
    setSelectedRowKeys(false);
    setisLoadingUpcoming(false);
  };
  const getColumnSearchPropsmodal = (dataIndex, isRemove = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearchmodal(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchmodal(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleResetmodal(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      onCell: (record) => {
        return {
          onClick: () => {
            okPatientModal(record.id);
          },
        };
      },
      ...getColumnSearchPropsmodal("name"),
      render: (record) => (
        <h2 style={{ cursor: "pointer" }} className="name-table">
          {record}
        </h2>
      ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : (
          <span style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}>
            Not Yet Verified
          </span>
        ),
      ...getColumnSearchPropsmodal("id"),
    },
  ];

  const handleSearchmodal = async (selectedKeys, confirm, dataIndex) => {
    setisLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length == 0) {
      await getpatientmodal();
    } else {
      let search = "";
      if (dataIndex == "name") search = "fullName";
      else search = "id";
      var res = await fetchData(
        `admin/patient/?verifiedAt=true&deletedAt=false&${search}=` +
          selectedKeys[0]
      );
      if (res.status == 200) {
        setPatients(
          res.data.data.map((temp) => {
            return { ...temp, name: temp.firstName + " " + temp.lastName };
          })
        );
      }
    }
    setisLoading(false);
  };
  const handleResetmodal = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    await getpatientmodal(1, true);
  };
  const getpatientmodal = async (page = 1, reload = false) => {
    setisLoading(true);
    var temp = patients;
    if (reload) {
      temp = [];
    }
    if (patients.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        `admin/patient/?deletedAt=false&verifiedAt=true&perPage=15&page=` + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat(res.data.data)
          );
        } else {
          setPatients(
            temp
              .filter((a, i) => {
                return a.firstName;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), serviceTypes: [] },
              ])
          );
        }
      }
    }

    setisLoading(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const data = patients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: new Date(p.createdAt).toDateString(),
    };
  });

  const getColumnSearchProps_appointment = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            if (dataIndex == "patientId") {
              okPatientModal(selectedKeys[0]);
            } else handleSearch_appointment(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              if (dataIndex == "patientId") {
                okPatientModal(selectedKeys[0]);
              } else handleSearch_appointment(selectedKeys, confirm, dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset_appointment(clearFilters);
            }}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearch_appointment = async (selectedKeys, confirm, dataIndex) => {
    setisLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
    } else {
      let search = "";
      if (dataIndex == "title") search = "title";

      if (dataIndex == "code") search = "code";

      var res = await fetchData(
        `admin/appointment-event/?deletedAt=false&perPage=1000&${search}=` +
          selectedKeys[0]
      );

      // admin/appointment-event/?deletedAt=false&perPage=15&page=
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });
      if (res.status == 200) {
        setdata_appointment(res.data.data);
      }
    }
    setisLoading(false);
  };
  const handleReset_appointment = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setdata_appointment([]);
    await fetchEvents(1, true);
  };

  const columns_appointment = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "15%",
      onCell: (record) => {
        return {
          onClick: () => {
            okAppintmentModal(record.id);
          },
        };
      },
      ...getColumnSearchProps_appointment("title"),
    },
    {
      title: "Location Type",
      dataIndex: "locationTypes",
      key: "locationTypes",
      width: "20%",

      responsive: ["md"],
      render: (locationTypes, record) => (
        <div>
          {locationTypes.map((s, i) => {
            let color = ["geekblue", "purple", "magenta"];

            return (
              <Tag color={color[i % 3]} key={s}>
                {config.appointment
                  ? config.appointment.event.locationType[s.type]
                  : ""}
              </Tag>
            );
          })}
        </div>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "15%",
      ...getColumnSearchProps_appointment("code"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "45%",
      // ...getColumnSearchProps_appointment("description"),
    },
  ];

  const columns_upcoming = [
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Title</div>{" "}
          <SearchOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              if (privilage.indexOf(access.todoList.indexEvent) > -1)
                setSearchappointmentModal(true);
            }}
          />
          <ReloadOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              getupcoming(1, true);
            }}
          />
        </div>
      ),
      dataIndex: "event",
      key: "event",
      width: "20%",
      render: (u) => u && <p>{u.title}</p>,
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Patient</div>{" "}
          <SearchOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              if (privilage.indexOf(access.todoList.indexPatient) > -1) {
                setIsAppointment(true);
                setSearchUserModal(true);
              }
            }}
          />
          <ReloadOutlined
            style={{ fontSize: "12px" }}
            onClick={() => {
              getupcoming(1, true);
            }}
          />
        </div>
      ),
      dataIndex: "",
      key: "x",
      width: "15%",
      render: (u) => (
        <a
          href={
            privilage.indexOf(access.patientManegment.showPatient) > -1 &&
            "/patients/" + u.patientId
          }
          style={{ color: "black" }}
        >
          {u && u.patient && u.patient.firstName
            ? u.patient.firstName + " " + u.patient.lastName
            : "undefined"}
        </a>
      ),
    },
    {
      title: "Type",
      dataIndex: "locationType",
      key: "locationType",
      width: "20%",
      render: (u) => (
        <div>
          {config.appointment ? config.appointment.event.locationType[u] : ""}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "startedAt",
      key: "startedAt",
      width: "15%",
      ...getColumnSearchProps_upcoming("startedAt"),
      render: (u) =>
        new Date(u) > new Date() ? (
          <p>
            {new Date(u)
              .toISOString()
              .replace(/^([^T]+)T([^\.]+)(.+)/, "$1 $2")
              .slice(0, -3)}
            <br />
            {parseInt((new Date(u) - new Date()) / (1000 * 60 * 60 * 24), 10) +
              "  "}
            Days left
          </p>
        ) : (
          <p>
            {new Date(u)
              .toISOString()
              .replace(/^([^T]+)T([^\.]+)(.+)/, "$1 $2")
              .slice(0, -3)}
          </p>
        ),
    },
    {
      title: "Checked by",
      dataIndex: "",
      width: "20%",
      key: "approvedBy",
      // ...getColumnSearchProps_appointment("patientId"),
      render: (u) =>
        u.approvedBy ? (
          <div>{u.approvedBy.firstName + " " + u.approvedBy.lastName}</div>
        ) : u.approvedAt ? (
          <div>Patient</div>
        ) : (
          <div>-</div>
        ),
    },
    {
      title: <div style={{ whiteSpace: "nowrap" }}>Checked at</div>,
      dataIndex: "",
      key: "x",
      width: "40%",
      render: (u) =>
        u.approvedAt
          ? new Date(u.approvedAt)
              .toISOString()
              .replace(/^([^T]+)T([^\.]+)(.+)/, "$1 $2")
              .slice(0, -3)
          : privilage.indexOf(access.todoList.check) > -1 && (
              <Button onClick={() => checked(u)} type="primary" ghost>
                Checked With Patient
              </Button>
            ),
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <div className="d-flex flex-row gap-2">
          {privilage.indexOf(access.todoList.addComment) > -1 && (
            <Button
              onClick={() => {
                setSelected({
                  patientId: u.patientId,
                  parchseStepId: u.serviceId,
                  firstName: u.patient.firstName,
                  lastName: u.patient.lastName,
                  patient: {
                    firstName: u.patient.firstName,
                    lastName: u.patient.lastName,
                    id: u.patientId,
                  },
                });
                setaddnotemodal(true);
              }}
              size={isMobile ? "small" : "default"}
            >
              <a
                style={{
                  color: "black",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                Add Comment
              </a>
            </Button>
          )}
          {privilage.indexOf(access.todoList.editappointment) > -1 && (
            <Button
              type="primary"
              size={isMobile ? "small" : "default"}
              onClick={() => {
                seteditappointmentmodalopen(true);
                setselectedappointment({ ...u });
              }}
            >
              <a
                style={{
                  //  color: "rgba(188, 35, 35, 1)",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                Edit
              </a>
            </Button>
          )}
        </div>
      ),
    },
  ];

  async function getupcoming(page = 1, relod = false) {
    let date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    setisLoadingUpcoming(true);

    if (dataSource2.length < (page - 1) * 15 + 2 || relod) {
      var res = await fetchData(
        `admin/appointment?deletedAt=false&startedAtGte=${year}-${month}-${day}&sortKey=startedAt&includes[]=event&includes[]=approvedBy&includes[]=patient&sortValue=1&perPage=15&page=` +
          // `admin/appointment?sortKey=startedAt&includes[]=event&includes=patient&status=active&sortValue=1&perPage=15&page=` +
          page
      );
      var temp = dataSource2;
      if (relod) {
        temp = [];
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdataSource2(
            temp
              .filter((a, i) => {
                return a.id;
              })
              .concat([...res.data.data])
          );
        } else {
          setdataSource2(
            temp
              .filter((a, i) => {
                return a.id;
              })
              .concat([...res.data.data, { startedAt: new Date() }])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
    }
    setisLoadingUpcoming(false);
    //
  }
  async function fetchEvents(page = 1, isReload = false) {
    var temp = isReload ? [] : data_appointment;
    setisLoadingUpcoming(true);
    if (data_appointment.length < (page - 1) * 15 + 2 || isReload) {
      var res = await fetchData(
        "admin/appointment-event/?deletedAt=false&perPage=15&page=" + page
      );
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdata_appointment(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat(res.data.data)
          );
        } else {
          setdata_appointment(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([
                ...res.data.data,
                { createdAt: new Date().toString(), locationTypes: [] },
              ])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
    }
    setisLoadingUpcoming(false);
  }
  useEffect(() => {
    getConfig();
    if (privilage.indexOf(access.todoList.showappointment) > -1) {
      getupcoming();
    }
    if (privilage.indexOf(access.todoList.indexPatient) > -1) {
      getpatientmodal();
    }
    if (privilage.indexOf(access.todoList.indexEvent) > -1) {
      fetchEvents();
    }
  }, []);

  useEffect(() => {
    let temp_events = [];
    dataSource2
      .filter((a, i) => {
        return a.id;
      })
      .map((temp) => {
        // console.log(temp.id);
        temp_events.push({
          id: temp.id,
          title: temp?.event?.title,
          allDay: false,
          start: new Date(2024, 11, 15, 9, 0), // December 15, 2024, 9:00 AM
          end: new Date(2024, 11, 15, 10, 30), // December 15, 2024, 10:30 AM
        });
        console.log(temp_events);
      });

    setCalandarEvents(temp_events);
  }, [dataSource2]);

  // {
  //   id: 0,
  //   title: "Board Meeting",
  //   allDay: false,
  //   start: new Date(2024, 11, 15, 9, 0), // December 15, 2024, 9:00 AM
  //   end: new Date(2024, 11, 15, 10, 30), // December 15, 2024, 10:30 AM
  // },
  return (
    <>
      <div
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={
            isMobile
              ? {
                  padding: "0px 20px",
                  // textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
              : {
                  padding: "0px 70px",
                  textAlign: "center",
                  margin: "auto",
                  minHeight: "50%",
                }
          }
        >
          <div
            style={
              isMobile
                ? {
                    padding: "40px 0px 20px 0px",
                    fontSize: "18px",
                  }
                : {
                    display: "flex",
                    justifyContent: "start",
                    padding: "40px 0px",
                    fontSize: "24px",
                  }
            }
          >
            <strong>Upcoming Appointments</strong>
            <Button
              style={{ position: "absolute", right: "5rem" }}
              onClick={async () => {
                setisLoading(true);
                setMeMode(!meMode);
                if (!meMode) {
                  await getMeMode();
                } else {
                  await getupcoming(1, true);
                }
                setisLoading(false);
              }}
              type={meMode ? "primary" : "default"}
              loading={isLoading}
              shape="round"
            >
              Assigned to me
            </Button>
          </div>
          {privilage.indexOf(access.todoList.showappointment) > -1 && (
            <Table
              scroll={{ x: "auto" }}
              size="small"
              columns={columns_upcoming}
              dataSource={dataSource2}
              loading={isLoadingUpcoming}
              pagination={{
                onChange: async (page) => {
                  await getupcoming(page);
                },

                pageSize: 15,
              }}
            />
          )}

          <hr style={{marginBottom:"50px"}} />

          <Calendar
            localizer={localizer}
            events={calendarEvent}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
          />
        </div>
      </div>

      <Modal
        title=""
        open={addnotemodal}
        top={"0"}
        width={isMobile ? "90vw" : "65vw"}
        footer={null}
        onCancel={() => setaddnotemodal(false)}
        closeIcon={null}
      >
        <AddComment
          isOpen={addnotemodal}
          setIsOpen={setaddnotemodal}
          getComments={() => {}}
          patients={[
            {
              id: selected.patientId,
              firstName: selected.firstName,
              lastName: selected.lastName,
            },
          ]}
          purchase={null}
          defultComment={selected}
          selecting={true}
          setDefult={setSelected}
        />
      </Modal>
      <Modal
        title=""
        open={editappointmentmodalopen}
        // onOk={handleOk}
        confirmLoading={isLoadingUpcoming}
        okText="Verify"
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          seteditappointmentmodalopen(false);
          setselectedappointment({});
        }}
      >
        <strong
          style={{
            textAlign: "center",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            fontSize: "24px",
          }}
        >
          Edit Appointment
        </strong>
        {/* <div style={{ marginBottom: "10px" }}>
          <strong style={{marginRight:'7px'}}>ID:</strong>
          {selectedappointment.id}
        </div> */}

        <div style={{ marginBottom: "10px", marginTop: "20px" }}>
          <strong style={{ marginRight: "7px" }}>LocationTypes:</strong>
          {selectedappointment.locationType}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <strong style={{ marginRight: "7px" }}>Start:</strong>
          {selectedappointment.startedAt &&
            selectedappointment.startedAt.substr(0, 16)}
        </div>
        <div style={{ marginBottom: "10px" }}>
          <strong style={{ marginRight: "7px" }}>Finish:</strong>
          {selectedappointment.finishedAt &&
            selectedappointment.finishedAt.substr(0, 16)}
        </div>
        <div className="d-flex flex-row" style={{ marginBottom: "10px" }}>
          <div>
            <strong style={{ marginRight: "7px" }}>URL:</strong>
          </div>
          <a href={selectedappointment.url} style={{ color: "black" }}>
            {selectedappointment.url}
          </a>
        </div>
        <hr />
        <Form
          form={form}
          layout="vertical"
          onFinish={async (values) => {
            setisLoadingUpcoming(true);
            var res = await putData(
              `admin/appointment/${selectedappointment.id}`,
              values
            );
            if (res.status == 200) {
              seteditappointmentmodalopen(false);
              await getupcoming(1, true);
              Modal.success({ title: "Appointments Updated Successfully." });
              form.resetFields();
            } else {
              Modal.error({ title: res });
            }
            setisLoadingUpcoming(false);
          }}
        >
          <Form.Item
            name="url"
            label="New URL"
            rules={[
              {
                type: "url",
                warningOnly: true,
              },
            ]}
          >
            <Input placeholder="Enter New URL" />
          </Form.Item>
          <Form.Item label="New LocationType" name="locationType">
            <Select placeholder="Enter new LocationType">
              <Select.Option value="online">Online</Select.Option>
              <Select.Option value="in_person">In-Person</Select.Option>
              <Select.Option value="online_form">
                Online form
              </Select.Option>{" "}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingUpcoming}
            >
              Submit
            </Button>
            <Button
              type="default"
              style={{ marginLeft: "10px" }}
              href={selectedappointment.rescheduleUrl}
            >
              Reschedule
            </Button>
            <Button
              type="default"
              onClick={() => {
                seteditappointmentmodalopen(false);
                setselectedappointment({});
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={SearchUserModal}
        title="Search Patient"
        width={1000}
        onOk={async () => {
          await okPatientModal();
        }}
        okText={"Ok"}
        onCancel={() => {
          setSearchUserModal(false);
          setSelectedRowKeys(false);
        }}
        style={isMobile ? {} : { maxHeight: "80vh" }}
      >
        <div
          style={
            isMobile
              ? { minWidth: "90%" }
              : { maxHeight: "75vh", overflowY: "scroll" }
          }
        >
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={columns2}
            dataSource={data}
            size="small"
            style={isMobile ? { margin: "0px" } : { margin: "40px" }}
            pagination={{
              onChange: async (page) => {
                await getpatientmodal(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
      <Modal
        style={{ maxHeight: "80vh" }}
        open={SearchappointmentModal}
        title="Search Appoitment Event"
        width={1000}
        onOk={async () => {
          await okAppintmentModal();
        }}
        okText={"Ok"}
        onCancel={() => {
          setSearchappointmentModal(false);
          setSelectedRowKeys(false);
        }}
      >
        <div style={isMobile ? {} : { maxHeight: "75vh", overflowY: "scroll" }}>
          <Table
            scroll={{ x: "auto" }}
            loading={isLoadingUpcoming}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            style={
              isMobile ? { minWidth: "80vw" } : { width: "90%", margin: "40px" }
            }
            columns={columns_appointment}
            dataSource={data_appointment}
            pagination={{
              onChange: async (page) => {
                await fetchEvents(page);
              },
              // total: 50,
              pageSize: 15,
            }}
          />
        </div>
      </Modal>
    </>
  );
}

export default Appointments;
