// import { ProtectedRoute, DashboardRoute } from "./protected";
import Authentication from "../pages/Authentication";
import ChangPassword from "../pages/ChangePassword";
import Dashboard from "../pages/Dashboard";
import ForgotPassword from "../pages/forgetPassword";
import SignIn from "../pages/signIn";
import SignUp from "../pages/signUp";
import Custom404 from "../pages/404/404";
import SendEmailPage from "../pages/SendEmail/sendEmail";

export const ROUTES = [
  {
    path: "/sign-in",
    Element: <SignIn />,
  },
  {
    path: "/sign-up",
    Element: <SignUp />,
  },
  {
    path: "/forgot-password",
    Element: <ForgotPassword />,
  },
  {
    path: "/auth",
    Element: <Authentication />,
  },
  {
    path: "/chang-password",
    Element: <ChangPassword />,
  },
  {
    path: "/404",
    Element: <Custom404 />,
  },
  {
    path: "/dashboard",
    Element: <Dashboard />,
  },
  {
    path: "/users",
    Element: <Dashboard defultActiveKey={1} />,
  },
  {
    path: "/toDo",
    Element: <Dashboard defultActiveKey={0} />,
  },

  {
    path: "/patients",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/services",
    Element: <Dashboard defultActiveKey={5} />,
  },
  {
    path: "/forms",
    Element: <Dashboard defultActiveKey={3} />,
  },
  {
    path: "/documents",
    Element: <Dashboard defultActiveKey={4} />,
  },
  {
    path: "/events",
    Element: <Dashboard defultActiveKey={6} />,
  },
  {
    path: "/article",
    Element: <Dashboard defultActiveKey={7} />,
  },
  {
    path: "/setting",
    Element: <Dashboard defultActiveKey={8} />,
  },
  {
    path: "/admin",
    Element: <Dashboard defultActiveKey={9} />,
  },
  {
    path: "/toDo/:option",
    Element: <Dashboard defultActiveKey={0} />,
  },
  {
    path: "/toDo",
    Element: <Dashboard defultActiveKey={0} />,
  },
  {
    path: "/users/:userId",
    Element: <Dashboard defultActiveKey={1} />,
  },
  {
    path: "/patients/:patientId",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/forms",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/documents",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/services",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/services/in-progress",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/Comments",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/reports",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/reports/:reportId",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/reports/:reportId/confirm",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/services/home/:serviceId",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/services/in-progress/:serviceId",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/patients/:patientId/services/in-progress/:serviceId/:tempStep",
    Element: <Dashboard defultActiveKey={2} />,
  },
  {
    path: "/send-email",
    Element: <SendEmailPage />,
  },
];
