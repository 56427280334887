import React, { useState } from "react";
import "./styles.scss";
import { useAssets } from "../../hooks/index";
import { useMobile } from "../../hooks/index";
import { Button, Form, Input, Select, Space, Dropdown } from "antd";
import ReactQuill from "react-quill";
import { DownOutlined } from "@ant-design/icons";

import "react-quill/dist/quill.snow.css";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

var modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

var formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

const items = [
  {
    label: "1st menu item",
    key: "1",
  },
  {
    label: "2nd menu item",
    key: "2",
  },
  {
    label: "3rd menu item",
    key: "3",
    danger: true,
  },
  {
    label: "4rd menu item",
    key: "4",
    danger: true,
    disabled: true,
  },
];
const menuProps = {
  items,
  // onClick: handleMenuClick,
};

function SendEmailPage() {
  const { getFile } = useAssets();
  const [form] = Form.useForm();
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  //   const [html, setHtml] = useState();

  return (
    <>
      <div
        style={{
          width: "100vw",
          minHeight: "100vh",
          backgroundColor: "white",
          padding: "3rem",
        }}
      >
        <img
          src={getFile("Logo")}
          style={{
            maxWidth: " 30vw",
          }}
        />
        <hr />

        <div style={{ padding: "1rem 1rem" }}>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            // onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              name="Email"
              label="Email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Email’s Template"
              label="Email’s Template"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Choose One"
                // onChange={onGenderChange}
                // allowClear
              >
                <Option value="male">male</Option>
                <Option value="female">female</Option>
              </Select>
            </Form.Item>

            {showCc && (
              <Form.Item
                name="cc"
                label="CC"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            )}

            {showBcc && (
              <Form.Item
                name="bcc"
                label="Bcc"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            )}
          </Form>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
              marginBottom: "38px",
            }}
          >
            <div style={{ cursor: "pointer" }}>Recipient</div>
            <div
              style={{ padding: "0px 16px", cursor: "pointer" }}
              onClick={() => setShowCc(!showCc)}
            >
              Cc
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShowBcc(!showBcc)}
            >
              BCc
            </div>
          </div>

          <Input className="subject-input" placeholder="Subject" />

          <ReactQuill
            // value={html}
            formats={formats}
            modules={modules}
            // onChange={onChange}
          />
          <div>
            <Dropdown menu={menuProps}>
              <Button type="primary" style={{ marginTop: "20px" }}>
                <Space>
                  Send
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendEmailPage;
