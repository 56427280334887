import { json } from "react-router-dom";
import { fetchData } from "../endpoints";

export const useConfig = () => {
  const getUserConfig = async () => {
    var tempTime = new Date();

    if (
      !localStorage.getItem("config") ||
      new Date(JSON.parse(localStorage.getItem("config")).expireAt) < tempTime
    ) {
      var res = await fetchData("user/config");
      tempTime.setMinutes(tempTime.getMinutes() + 30);
      res = { ...res, expireAt: tempTime };
      localStorage.setItem("config", JSON.stringify(res));
    }
    return;
  };

  return { getUserConfig };
};
