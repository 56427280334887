// import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import PulseLoader from "react-spinners/PulseLoader";

export const CustomButton = ({
  uiType = "primary",
  additionalClassNames = "",
  href = "",
  link = "",
  blank = false,
  title,
  fullWidth = false,
  icon = "",
  onClick = () => {},
  loading = false,
  ...rest
}) => {
  const renderChildren = () => {
    if (href) {
      return (
        <a
          href={href}
          target={blank ? "_blank" : "_self"}
          rel="noreferrer"
          className={`custom-btn ${uiType} ${additionalClassNames} ${
            fullWidth ? "w-100" : ""
          }`}
          {...rest}
        >
          {icon ? (
            <div className="d-flex justify-content-center align-items-center">
              {icon}
              <span className="d-inline-block ms-2">{title}</span>
            </div>
          ) : loading ? (
            <PulseLoader
              size={10}
              loading={loading}
              color={
                uiType == "outlined"
                  ? "#a1813a"
                  : uiType == "outlined2"
                  ? "#18324e"
                  : "#ffff"
              }
            />
          ) : (
            title
          )}
        </a>
      );
    } else if (link) {
      return (
        <Link
          to={link}
          className={`custom-btn ${uiType} ${additionalClassNames} ${
            fullWidth ? "w-100" : ""
          }`}
          {...rest}
        >
          {icon ? (
            <div className="d-flex justify-content-center align-items-center">
              {icon}
              <span className="d-inline-block ms-2">{title}</span>
            </div>
          ) : loading ? (
            <PulseLoader
              size={10}
              loading={loading}
              color={
                uiType == "outlined"
                  ? "#a1813a"
                  : uiType == "outlined2"
                  ? "#18324e"
                  : "#ffff"
              }
            />
          ) : (
            title
          )}
        </Link>
      );
    } else {
      return (
        <button
          onClick={onClick}
          className={`custom-btn ${uiType} ${additionalClassNames} ${
            fullWidth ? "w-100" : ""
          }`}
          {...rest}
        >
          {icon ? (
            <div className="d-flex justify-content-center align-items-center">
              <PulseLoader
                size={10}
                loading={loading}
                color={
                  uiType == "outlined"
                    ? "#a1813a"
                    : uiType == "outlined2"
                    ? "#18324e"
                    : "#ffff"
                }
              />
              {icon}
              <span className="d-inline-block ms-2">{title}</span>
            </div>
          ) : loading ? (
            <PulseLoader
              size={10}
              loading={loading}
              color={
                uiType == "outlined"
                  ? "#a1813a"
                  : uiType == "outlined2"
                  ? "#18324e"
                  : "#ffff"
              }
            />
          ) : (
            title
          )}
        </button>
      );
    }
  };

  return renderChildren();
};
