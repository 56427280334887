import React from "react";
import CommentCard from "./commentCard";
import { fetchData } from "../../../endpoints";
import { useMobile } from "../../../hooks";
import { useEffect, useState } from "react";

import {
  Button,
  Modal,
  Pagination,
  Spin,
  Dropdown,
  Space,
  Input,
  Select,
  DatePicker,
  Mentions,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";

import AddComment from "./addComment";
import "./style.scss";
import access from "../../../assets/access";
import Cookies from "js-cookie";
import full from "../../../assets/fullAccess";
import { useLocation, useNavigate } from "react-router-dom";
const privilage = Cookies.get("privilege") ?? full;

function CommentPage({ patientId = null, patient = null }) {
  const isMobile = useMobile();
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [search, setsearch] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setopen] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [searchType, setsearchType] = useState("text");
  const [users, setUsers] = useState([]);
  const [patients, setPatients] = useState([]);
  const [purchase, setPurchase] = useState([]);
  const [me, setMe] = useState({});
  const [inputtemp, setinputtemp] = useState("");
  const path = useLocation().pathname;

  const [defult,setDefult]=useState(patientId?{patient:patient,patientId:patientId}:{})

  const options = patientId
    ? [
        {
          value: "text",
          label: "Content",
        },
        {
          value: "reporterId",
          label: "Reporter",
        },
        {
          value: "assignees",
          label: "Assign",
        },
        {
          value: "watchers",
          label: "Viewer",
        },
        {
          value: "purchaseId",
          label: "Purchase",
        },
        {
          value: "dueDate",
          label: "Deadline",
        },
      ]
    : [
        {
          value: "text",
          label: "Content",
        },
        {
          value: "reporterId",
          label: "Reporter",
        },
        {
          value: "patientId",
          label: "Patient",
        },
        {
          value: "assignees",
          label: "Assign",
        },
        {
          value: "watchers",
          label: "Viewer",
        },
        {
          value: "purchaseId",
          label: "Purchase",
        },
        {
          value: "dueDate",
          label: "Deadline",
        },
      ];

  const getUsers = async () => {
    var res = await fetchData(
      `admin/user/?disabledAt=false&deletedAt=false&perPage=200&page=1&type=admin&type=super_user`
    );
    if (res.status == 200) {
      var res2 = await fetchData(`user`);
      if (res2.status === 200) {
        setMe(res2.data.data);
      }
      setUsers(res.data.data);
    } else {
      Modal.error({ title: res });
    }
  };
  const getPatients = async () => {
    var res = await fetchData(
      `admin/patient/?deletedAt=false&perPage=200&page=1`
    );
    if (res.status == 200) {
      setPatients(res.data.data);
    } else {
      Modal.error({ title: res });
    }
  };
  const getPurchase = async () => {
    var res = await fetchData(
      `admin/service-purchase/?&status=in_progress&includes[]=patient&deletedAt=false&perPage=100&page=1`
    );
    if (res.status == 200) {
      setPurchase(res.data.data);
    } else {
      Modal.error({ title: res });
    }
  };
  async function getComments(page = 1, reload = false, isSearch = false) {
    setIsLoading(true);
    setCurrent(page);
    var temp = comments;
    if (reload) {
      temp = [];
    }
    if (comments.length < (page - 1) * 10 + 2 || reload || isSearch) {
      var res;
      if (isSearch) {
        var SerchText = isSearch
          .map((s, i) => {
            var test;
            if (s.type == "dueDate") {
              var date =
                s.content.substr(0, 8) + (parseInt(s.content.substr(8, 2)) + 1);
              test = `dueDateGte=${s.content}&dueDateLte=${date}`;
            } else {
              test =
                s.type == "assignees" || s.type == "watchers"
                  ? s.type + "[]=" + s.content
                  : s.type + "=" + s.content;
            }
            if (i != isSearch.length - 1) test = test + "&";
            return test;
          })
          .toString()
          .replace(",", "");
        if (patientId) {
          res = await fetchData(
            `admin/comment?perPage=10&parentId=0&deletedAt=false&includes[]=watchers&patientId=${patientId}&includes[]=reporter&includes[]=assignees&includes[]=attachments&includes[]=replies&${SerchText}&page=` +
              page
          );
        } else {
          res = await fetchData(
            `admin/comment?perPage=10&parentId=0&deletedAt=false&includes[]=watchers&includes[]=reporter&includes[]=assignees&includes[]=attachments&includes[]=replies&${SerchText}&page=` +
              page
          );
        }
      } else {
        if (patientId) {
          res = await fetchData(
            `admin/comment?perPage=10&parentId=0&deletedAt=false&includes[]=watchers&includes[]=reporter&patientId=${patientId}&includes[]=assignees&includes[]=attachments&includes[]=replies&page=` +
              page
          );
        } else {
          res = await fetchData(
            `admin/comment?perPage=10&parentId=0&deletedAt=false&includes[]=watchers&includes[]=reporter&includes[]=assignees&includes[]=attachments&includes[]=replies&page=` +
              page
          );
        }
      }
      if (res.status === 200) {
        if (res.data.data.length < 10) {
          setComments(
            temp
              .filter((a, i) => {
                return a.text;
              })
              .concat(
                res.data.data.filter((a) => {
                  return !a.deletedAt;
                })
              )
          );
        } else {
          setComments(
            temp
              .filter((a, i) => {
                return a.text;
              })
              .concat([
                ...res.data.data.filter((a) => {
                  return !a.deletedAt;
                }),
                { tempId: 0 },
              ])
          );
        }
      }
    }
    setIsLoading(false);
  }
  const items = [
    {
      key: "1",
      label: (
        <div style={{ padding: "20px" , }}>
          <svg
            onClick={() => {
              setopen(false);
            }}
            style={{
              width: "12px",
              height: "12px",
              position: "absolute",
              right: "10px",
              marginTop: "-20px",
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3644_121137)">
              <path
                d="M0.418896 23.4842C1.01733 24.0813 1.93529 24.0813 2.53377 23.4842L11.951 14.089L21.3682 23.4842C21.9268 24.1213 22.8445 24.161 23.483 23.6434C24.1215 23.1257 24.1613 22.1704 23.6425 21.5334C23.6027 21.4937 23.5626 21.414 23.483 21.3743L14.0658 11.9791L23.483 2.58384C24.0815 2.02651 24.1215 1.07098 23.5629 0.473901C23.0043 -0.12314 22.0465 -0.163128 21.448 0.394202L11.951 9.86904L2.53385 0.473822C1.93541 -0.0835074 0.977621 -0.0437966 0.418974 0.553521C-0.0998546 1.11085 -0.0998546 2.02643 0.418974 2.58377L9.83616 11.979L0.418974 21.3742C-0.139658 21.9713 -0.139658 22.8871 0.418974 23.4842L0.418896 23.4842Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_3644_121137">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <Space.Compact>
            <div className="d-flex flex-row align-items-center justify-content-center gap-2">
              <div className="d-flex flex-row">Where</div>
              <Select
                defaultValue="text"
                options={options}
                onChange={async (value) => {
                  setsearchType(value);
                }}
              />
              {searchType == "text" ? (
                <Input
                  style={{ marginLeft: "10px", width: "400px" }}
                  placeholder="Search"
                  value={inputtemp}
                  onChange={(e) => {
                    setinputtemp(e.target.value);
                    setsearchText(e.target.value);
                  }}
                />
              ) : searchType == "dueDate" ? (
                <DatePicker
                  onChange={(value) => {
                    setinputtemp(
                      new Date(value).toISOString().substring(0, 10)
                    );
                    setsearchText(
                      new Date(value).toISOString().substring(0, 10)
                    );
                  }}
                  style={{ marginLeft: "10px", width: "400px" }}
                />
              ) : searchType == "patientId" ? (
                <Select
                  style={{ marginLeft: "10px", width: "400px" }}
                  onChange={(value) => {
                    setsearchText(value);
                  }}
                >
                  {patients.map((p) => {
                    return (
                      <Select.Option value={p.id}>
                        {p.firstName + " " + p.lastName}
                      </Select.Option>
                    );
                  })}
                </Select>
              ) : searchType == "purchaseId" ? (
                <Select
                  style={{ marginLeft: "10px", width: "400px" }}
                  onChange={async (value) => {
                    setsearchText(value);
                  }}
                >
                  {purchase.map((p) => {
                    return (
                      <Select.Option value={p.id}>
                        {p.service.title +
                          "-" +
                          p.patient.firstName +
                          " " +
                          p.patient.lastName}
                      </Select.Option>
                    );
                  })}
                </Select>
              ) : (
                <Mentions
                  style={{ marginLeft: "10px", width: "400px" }}
                  placeholder="@email"
                  onSelect={(value) => {
                    setinputtemp(
                      users.filter((u) => {
                        return u.email == value.key;
                      })[0].id
                    );
                    setsearchText(
                      users.filter((u) => {
                        return u.email == value.key;
                      })[0].id
                    );
                  }}
                  options={users.map((u) => {
                    return { value: u.email, label: u.email };
                  })}
                />
              )}
              <Button
                loading={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  if (searchType && searchText) {
                    var tempSearch = search;
                    search.map((temp, index) => {
                      if (temp.type == searchType) {
                        tempSearch.splice(index, 1);
                        setsearch([...tempSearch]);
                      }
                    });

                    setsearch([
                      ...search,
                      { type: searchType, content: searchText },
                    ]);
                    await getComments(1, true, [
                      ...search,
                      { type: searchType, content: searchText },
                    ]);
                  }
                  setIsLoading(false);
                }}
              >
                <svg
                  style={{ marginRight: "3px" }}
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.48362 1.68445C8.18324 1.68885 7.89689 1.81232 7.68749 2.02774C7.47809 2.24315 7.36278 2.53289 7.36689 2.83328V7.36661H2.83356C2.68338 7.36449 2.53427 7.39223 2.39491 7.44823C2.25554 7.50424 2.1287 7.58738 2.02175 7.69283C1.9148 7.79828 1.82987 7.92393 1.7719 8.06249C1.71393 8.20105 1.68408 8.34975 1.68408 8.49994C1.68408 8.65014 1.71393 8.79883 1.7719 8.93739C1.82987 9.07595 1.9148 9.20161 2.02175 9.30706C2.1287 9.41251 2.25554 9.49565 2.39491 9.55165C2.53427 9.60766 2.68338 9.6354 2.83356 9.63328H7.36689V14.1666C7.36477 14.3168 7.39251 14.4659 7.44851 14.6053C7.50452 14.7446 7.58766 14.8715 7.69311 14.9784C7.79856 15.0854 7.92421 15.1703 8.06277 15.2283C8.20133 15.2862 8.35003 15.3161 8.50022 15.3161C8.65042 15.3161 8.79912 15.2862 8.93767 15.2283C9.07623 15.1703 9.20189 15.0854 9.30734 14.9784C9.41279 14.8715 9.49593 14.7446 9.55193 14.6053C9.60794 14.4659 9.63568 14.3168 9.63356 14.1666V9.63328H14.1669C14.3171 9.6354 14.4662 9.60766 14.6055 9.55165C14.7449 9.49565 14.8717 9.41251 14.9787 9.30706C15.0857 9.20161 15.1706 9.07595 15.2285 8.93739C15.2865 8.79883 15.3164 8.65014 15.3164 8.49994C15.3164 8.34975 15.2865 8.20105 15.2285 8.06249C15.1706 7.92393 15.0857 7.79828 14.9787 7.69283C14.8717 7.58738 14.7449 7.50424 14.6055 7.44823C14.4662 7.39223 14.3171 7.36449 14.1669 7.36661H9.63356V2.83328C9.63563 2.68174 9.60729 2.53133 9.55022 2.39094C9.49315 2.25055 9.40851 2.12303 9.3013 2.01592C9.19409 1.90881 9.06648 1.82429 8.92604 1.76735C8.78559 1.71042 8.63515 1.68223 8.48362 1.68445Z"
                    fill="#5B5B5B"
                  />
                </svg>
                Add filter
              </Button>
            </div>
          </Space.Compact>
          <div style={{ display: "flex", marginTop: "10px" }}>
            {search.map((temp, index) => {
              return (
                <div
                  style={{
                    borderRadius: "5px",
                    padding: "5px",
                    marginRight: "5px",
                    backgroundColor: "#d6e4ff",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {temp.type=="watchers"?"Viewer":temp.type}
                  {"  "}:{"  "}
                  {temp.content}
                  <CloseOutlined
                    style={{ marginLeft: "5px", color: "black" }}
                    onClick={async () => {
                      var temp = search;
                      if (temp[index].type == "reporterId") {
                        setMe({ ...me, isMe: false });
                      }
                      temp.splice(index, 1);
                      setsearch([...temp]);
                      await getComments(1, true, [...temp]);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ),
    },
  ];
  const sortItems = [
    { key: "dueDate", label: "Due Date" },
    { key: "createdAt", label: "Create Date" },
  ];
  const sortOnclick = async (item) => {
    await getComments(1, true, [
      ...search,
      { type: "sortKey", content: item.key },
      { type: "sortValue", content: "-1" },
    ]);
  };
  useEffect(() => {
    privilage.indexOf(access.userManegment.indexUser) > -1 && getUsers();
    privilage.indexOf(access.todoList.indexComment) > -1 && getComments();
    privilage.indexOf(access.patientManegment.indexPatient) > -1 &&
      getPatients();
    privilage.indexOf(access.patientManegment.service.indexpurchase) > -1 &&
      getPurchase();
  }, []);
  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        dir="ltr"
        style={
          isMobile
            ? {
                padding: "0px 10px",
                // textAlign: "center",
                // margin: "auto",
                minHeight: "50%",
              }
            : {
                padding: "40px 40px",
                // textAlign: "center",
                // margin: "auto",
                minHeight: "50%",
              }
        }
      >
        <div
          style={
            isMobile
              ? {
                  padding: "40px 0px 20px 10px",
                  fontSize: "18px",
                }
              : {
                  display: "flex",
                  justifyContent: "start",
                  padding: "15px 0px",
                  fontSize: "24px",
                }
          }
        >
          <strong
            style={isMobile ? { fontSize: "16px" } : { fontSize: "24px" }}
          >
            Comments
          </strong>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            open={open && path.includes("Comment")}
            
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                setopen((prev) => !prev);
              }}
              style={
                search.length > 0
                  ? {
                      color: "#0958d9",
                      margin: "0px 0px",
                      padding: "2px 15px",
                      border: "1px solid #0958d9",
                      display: "flex",
                      borderRadius: "3px",
                    }
                  : { color: "#8c8c8c", display: "flex" }
              }
            >
              <Space>
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.000118256 2.22017C0.000118256 2.85427 0.514109 3.36849 1.14844 3.36849H18.8326C19.467 3.36849 19.981 2.85427 19.981 2.22017V1.14832C19.981 0.51422 19.467 0 18.8326 0H1.14844C0.514109 0 0.000118256 0.51422 0.000118256 1.14832V2.22017Z"
                    fill={search.length > 0 ? "#0958d9" : "#8C8C8C"}
                  />
                  <path
                    d="M3.44524 8.18185C3.44524 8.81619 3.95923 9.33018 4.59356 9.33018H15.3878C16.0221 9.33018 16.5361 8.81619 16.5361 8.18185V7.11024C16.5361 6.4759 16.0221 5.96191 15.3878 5.96191H4.59356C3.95923 5.96191 3.44524 6.4759 3.44524 7.11024V8.18185Z"
                    fill={search.length > 0 ? "#0958d9" : "#8C8C8C"}
                  />
                  <path
                    d="M6.88987 14.1438C6.88987 14.7779 7.40386 15.2921 8.03819 15.2921H11.9425C12.5768 15.2921 13.0908 14.7779 13.0908 14.1438V13.0719C13.0908 12.4378 12.5768 11.9236 11.9425 11.9236H8.03819C7.40386 11.9236 6.88987 12.4378 6.88987 13.0719V14.1438Z"
                    fill={search.length > 0 ? "#0958d9" : "#8C8C8C"}
                  />
                </svg>
                Filter
              </Space>
            </a>
          </Dropdown>
          <div
            style={{
              color: "#8c8c8c",
              margin: "0px 15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Dropdown
              menu={{
                items: sortItems,
                onClick: sortOnclick,
                selectable: true,
              }}
            >
              <Space style={{ cursor: "pointer" }}>
                {" "}
                <svg
                  width="11"
                  height="20"
                  viewBox="0 0 11 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "5px" }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.49028 11.9258C10.1318 11.5862 10.3483 11.8109 9.97125 12.4319L5.58773 19.6518C5.3064 20.1152 4.84946 20.1138 4.56895 19.6518L0.185433 12.4319C-0.190473 11.8127 0.024135 11.5858 0.666408 11.9258L1.02043 12.1132C3.26155 13.2997 6.89562 13.2994 9.13625 12.1132L9.49028 11.9258ZM0.666408 8.07301C0.0248665 8.41265 -0.191589 8.18791 0.185433 7.56693L4.56895 0.347018C4.85028 -0.116347 5.30722 -0.114998 5.58773 0.347018L9.97125 7.56693C10.3472 8.18607 10.1325 8.41304 9.49028 8.07301L9.13625 7.88559C6.89513 6.69911 3.26107 6.69937 1.02043 7.88559L0.666408 8.07301Z"
                    fill="#8C8C8C"
                  />
                </svg>
                Sort by
              </Space>
            </Dropdown>
          </div>
          {patientId ? (
            <></>
          ) : (
            <div
              style={
                me.isMe
                  ? {
                      marginRight: "30px",
                      color: "#0958d9",
                      margin: "0px 0px",
                      padding: "2px 15px",
                      border: "1px solid #0958d9",
                      borderRadius: "3px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }
                  : {
                      marginRight: "30px",
                      color: "#8c8c8c",
                      margin: "2px 15px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }
              }
              onClick={async () => {
                if (me.isMe) {
                  setMe({ ...me, isMe: false });
                  setsearch(
                    search.filter((s) => {
                      return s.type != "reporterId";
                    })
                  );
                  await getComments(
                    1,
                    true,
                    search.filter((s) => {
                      return s.type != "reporterId";
                    })
                  );
                } else {
                  setMe({ ...me, isMe: true });
                  setsearch([
                    ...search,
                    { type: "reporterId", content: me.id },
                  ]);
                  await getComments(1, true, [
                    ...search,
                    { type: "reporterId", content: me.id },
                  ]);
                }
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "5px" }}
              >
                <path
                  d="M17.875 19H2.125C1.82675 18.9996 1.54083 18.881 1.32993 18.6701C1.11904 18.4592 1.00039 18.1733 1 17.875V16.7712C1.00068 16.0276 1.24654 15.3051 1.6995 14.7155C2.15245 14.1259 2.78721 13.7021 3.50543 13.5099L6.52173 12.7056C8.80078 12.0981 11.1992 12.0981 13.4783 12.7056L16.4946 13.5098C17.2128 13.7021 17.8476 14.1259 18.3005 14.7155C18.7535 15.3051 18.9993 16.0276 19 16.7712V17.875C18.9996 18.1733 18.881 18.4592 18.6701 18.6701C18.4592 18.881 18.1733 18.9996 17.875 19ZM10 13.3753C8.92365 13.3751 7.85186 13.5152 6.81175 13.7922L3.79544 14.5964C3.31657 14.7247 2.89335 15.0073 2.59136 15.4004C2.28937 15.7936 2.12545 16.2754 2.125 16.7712V17.875H17.875V16.7712C17.8745 16.2754 17.7106 15.7936 17.4086 15.4004C17.1066 15.0073 16.6834 14.7247 16.2045 14.5964L13.1882 13.7922C12.1481 13.5152 11.0764 13.3751 10 13.3753ZM10 10C9.10998 10 8.23996 9.73608 7.49993 9.24161C6.75991 8.74715 6.18314 8.04434 5.84254 7.22208C5.50195 6.39981 5.41283 5.49501 5.58647 4.6221C5.7601 3.74918 6.18868 2.94736 6.81802 2.31802C7.44736 1.68868 8.24918 1.2601 9.12209 1.08647C9.99501 0.912835 10.8998 1.00195 11.7221 1.34254C12.5443 1.68314 13.2471 2.25991 13.7416 2.99994C14.2361 3.73996 14.5 4.60999 14.5 5.5C14.4986 6.69306 14.0241 7.83686 13.1805 8.68048C12.3369 9.52409 11.1931 9.99864 10 10ZM10 2.125C9.33249 2.125 8.67997 2.32294 8.12495 2.69379C7.56993 3.06464 7.13735 3.59174 6.88191 4.20844C6.62646 4.82515 6.55962 5.50375 6.68985 6.15843C6.82007 6.81312 7.14151 7.41448 7.61351 7.88649C8.08552 8.35849 8.68688 8.67993 9.34157 8.81015C9.99626 8.94038 10.6749 8.87354 11.2916 8.61809C11.9083 8.36265 12.4354 7.93007 12.8062 7.37505C13.1771 6.82004 13.375 6.16751 13.375 5.5C13.374 4.6052 13.0181 3.74732 12.3854 3.1146C11.7527 2.48187 10.8948 2.12598 10 2.125Z"
                  fill="#8C8C8C"
                  stroke={me.isMe ? "#0958d9" : "#8C8C8C"}
                  stroke-width="0.4"
                />
              </svg>
              Me
            </div>
          )}
        </div>

        <hr />
        {isLoading ? (
          <div
            className="w-100 h-100"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            {comments
              .slice((current - 1) * 10, current * 10 - 1)
              .map((comment) => {
                return (
                  <CommentCard
                    purchase={purchase}
                    comment={comment}
                    getComments={getComments}
                    Users={users}
                    filter={search}
                    setDefult={setDefult}
                    openAddComment={setIsOpen}
                    patient={patient}
                  />
                );
              })}
            <Pagination
              style={{ marginTop: "20px" }}
              total={comments.length}
              pageSize={10}
              current={current}
              onChange={async (page) => {
                if (search.length > 0) await getComments(page, false, search);
                else await getComments(page);
              }}
            />
          </>
        )}
        {privilage.indexOf(access.todoList.addComment) > -1 && (
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            type="primary"
            size="large"
            style={
              isMobile
                ? {
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    fontSize: "14px",
                  }
                : { position: "fixed", bottom: "70px", right: "70px" }
            }
          >
            <svg
              width={isMobile ? "14" : "22"}
              height={isMobile ? "14" : "22"}
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "5px" }}
            >
              <path
                d="M10.9745 0.576358C10.5151 0.58309 10.0771 0.771933 9.75688 1.10139C9.43662 1.43085 9.26026 1.87397 9.26655 2.33339V9.26672H2.33322C2.10353 9.26347 1.87549 9.30591 1.66234 9.39156C1.4492 9.47721 1.2552 9.60437 1.09163 9.76565C0.928051 9.92692 0.798162 10.1191 0.709506 10.331C0.62085 10.5429 0.575195 10.7703 0.575195 11.0001C0.575195 11.2298 0.62085 11.4572 0.709506 11.6691C0.798162 11.881 0.928051 12.0732 1.09163 12.2345C1.2552 12.3957 1.4492 12.5229 1.66234 12.6086C1.87549 12.6942 2.10353 12.7366 2.33322 12.7334H9.26655V19.6667C9.2633 19.8964 9.30574 20.1245 9.39139 20.3376C9.47704 20.5507 9.60419 20.7447 9.76547 20.9083C9.92675 21.0719 10.1189 21.2018 10.3308 21.2904C10.5428 21.3791 10.7702 21.4247 10.9999 21.4247C11.2296 21.4247 11.457 21.3791 11.6689 21.2904C11.8808 21.2018 12.073 21.0719 12.2343 20.9083C12.3956 20.7447 12.5227 20.5507 12.6084 20.3376C12.694 20.1245 12.7365 19.8964 12.7332 19.6667V12.7334H19.6665C19.8962 12.7366 20.1243 12.6942 20.3374 12.6086C20.5506 12.5229 20.7446 12.3957 20.9081 12.2345C21.0717 12.0732 21.2016 11.881 21.2903 11.6691C21.3789 11.4572 21.4246 11.2298 21.4246 11.0001C21.4246 10.7703 21.3789 10.5429 21.2903 10.331C21.2016 10.1191 21.0717 9.92692 20.9081 9.76565C20.7446 9.60437 20.5506 9.47721 20.3374 9.39156C20.1243 9.30591 19.8962 9.26347 19.6665 9.26672H12.7332V2.33339C12.7364 2.10163 12.693 1.87159 12.6058 1.65688C12.5185 1.44216 12.389 1.24713 12.2251 1.08331C12.0611 0.919501 11.8659 0.790233 11.6511 0.703157C11.4363 0.616081 11.2062 0.572964 10.9745 0.576358Z"
                fill="white"
              />
            </svg>
            Comment{" "}
          </Button>
        )}
      </div>
      <Modal
        closable={false}
        open={isOpen}
        footer={[null, null]}
        width={isMobile ? "90vw" : "65vw"}
        onCancel={()=>{
          setDefult(patientId?{patient:patient, patientId:patientId}:{})
        }}
        destroyOnClose={true}
      >
        <AddComment
          isOpen={isOpen}
          getComments={getComments}
          setIsOpen={setIsOpen}
          purchase={purchase}
          patients={patients}
          defultComment={defult}
          setDefult={setDefult}
        />
      </Modal>
    </div>
  );
}

export default CommentPage;
