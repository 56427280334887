import { useEffect } from "react";
import { useAssets } from "../../hooks";
import { Spin, Modal, Button } from "antd";
import { fetchData } from "../../endpoints";
import { useMobile } from "../../hooks/useMobile";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const ServiceView = ({
  service,
  setIsOpen,
  setService,
  setLoading,
  loading,
  events,
  Publish,
  Edit,
  Deactive,
  Duplicate,
  Remove,
}) => {
  const { getFile } = useAssets();
  const convertStringToHTML = () => {
    let element = document.getElementById("myElement");
    if (element) element.innerHTML = service.description;
  };

  const isMobile = useMobile();
  return (
    <div
      className={`service-view d-flex flex-column gap-5 ${
        isMobile ? "px-3" : ""
      }`}
    >
      {convertStringToHTML()}

      <svg
        onClick={() => {
          setIsOpen(false);
        }}
        style={{ marginBottom: "-30px" }}
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.8016 8.39557H2.6553L10.0228 1.01421C10.2548 0.78219 10.2548 0.40603 10.0228 0.174013C9.79085 -0.0580043 9.41481 -0.0580043 9.18286 0.174013L0.783422 8.57595C0.667448 8.68473 0.601562 8.83691 0.601562 8.99605C0.601562 9.15519 0.667451 9.30737 0.783422 9.41615L9.18286 17.8181C9.2916 17.9341 9.44374 18 9.60283 18C9.76193 18 9.91406 17.9341 10.0228 17.8181C10.1388 17.7093 10.2047 17.5571 10.2047 17.398C10.2047 17.2388 10.1388 17.0867 10.0228 16.9779L2.6553 9.59653H22.8016C23.1329 9.59653 23.4016 9.32781 23.4016 8.99639C23.4016 8.66498 23.1329 8.39625 22.8016 8.39625V8.39557Z"
          fill="#18324E"
        />
      </svg>

      <div
        style={{ marginBottom: "-30px" }}
        className="d-flex flex-row-reverse col-12 "
      >
        {!service.publishedAt && (
          <Button
            onClick={() => {
              Publish(service);
            }}
            type="text"
            style={{ fontSize: "14px", fontWeight: "700", color: "#2E6CDF" }}
          >
            Publish
          </Button>
        )}
        <Button
          onClick={() => {
            Modal.confirm({
              title: "Are You Sure to Duplicate this service?",
              okText: "Yes I’m sure",
              cancelText: "Decline",
              onOk: async () => {
                await Duplicate(service);
              },
            });
          }}
          type="text"
          style={{ fontSize: "14px", fontWeight: "700", color: "#4C4C4C" }}
        >
          Duplicate
        </Button>
        <Button
          onClick={() => {
            Edit(service);
          }}
          type="text"
          style={{ fontSize: "14px", fontWeight: "700", color: "#4C4C4C" }}
        >
          Edit
        </Button>
        {service.publishedAt && !service.deactivedAt && (
          <Button
            onClick={() => {
              Modal.confirm({
                title: "Are You Sure to Deactive this service?",
                okText: "Yes I’m sure",
                cancelText: "Decline",
                onOk: async () => {
                  await Deactive(service);
                },
              });
            }}
            type="text"
            style={{ fontSize: "14px", fontWeight: "700", color: "#4C4C4C" }}
          >
            Deactive
          </Button>
        )}
        <Button
          onClick={() => {
            Remove(service);
          }}
          type="text"
          style={{ fontSize: "14px", fontWeight: "700", color: "#BC2323" }}
        >
          Delete
        </Button>
      </div>
      <div className="h-line" />
      <div
        style={{ marginTop: "-30px" }}
        className={`service-info-box  ${
          !isMobile ? "gap-5 d-flex flex-row" : "gap-3"
        } justify-content-center align-items-center`}
      >
        <img
          //   width={"60%"}
          src={
            service.cover
              ? "https://allbrains-dashboard-stg-public.s3.amazonaws.com/" +
                service.cover
              : getFile("noImg")
          }
          className={isMobile ? "col-12" : "col-lg-3 col-6"}
        />
        <div
          className={
            isMobile
              ? "d-flex flex-column  col-12"
              : "d-flex flex-column  col-lg-7 col-4"
          }
        >
          <h2
            style={
              isMobile
                ? { fontWeight: "700", fontSize: "18px", paddingTop: "10px" }
                : { fontWeight: "700", fontSize: "24px" }
            }
          >
            {service.title}
          </h2>
          <h3
            style={
              isMobile
                ? { fontWeight: "700", fontSize: "12px", color: "#4C4C4C" }
                : { fontWeight: "700", fontSize: "16px", color: "#4C4C4C" }
            }
          >
            {service.types[0].serviceType
              ? service.types
                  .map((s, i) => {
                    if (i != 0) {
                      return ", " + s.serviceType.title;
                    } else {
                      return s.serviceType.title;
                    }
                  })
                  .toString()
              : null}
          </h3>
          <div
            id="myElement"
            style={
              isMobile
                ? {
                    textAlign: "justify",
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#4C4C4C",
                  }
                : {
                    textAlign: "justify",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#4C4C4C",
                  }
            }
          />
          {service.activeDays && (
            <span
              style={
                isMobile
                  ? { fontWeight: "500", fontSize: "12px", color: "#9E9E9E" }
                  : { fontWeight: "500", fontSize: "14px", color: "#9E9E9E" }
              }
            >
              Duration: {service.activeDays}days
            </span>
          )}
        </div>
      </div>

      <h3
        style={{
          fontWeight: "700",
          fontSize: "24px",
          marginBottom: "-40px",
          marginLeft: "20px",
        }}
      >
        Steps
      </h3>
      <div className="h-line" />
      {service.steps.map((s, i) => {
        return (
          <StepView
            step={s}
            i={i}
            loading={loading}
            setLoading={setLoading}
            setService={setService}
            service={service}
            events={events}
            isMobile={isMobile}
          />
        );
      })}
    </div>
  );
};

const StepView = ({
  service,
  setService,
  step,
  i,
  setLoading,
  loading,
  events,
  isMobile,
}) => {
  const getCondition = async (step, i) => {
    setLoading(true);
    if (step.id) {
      var res = await fetchData(`admin/service-step/${step.id}/block`);
      var res2 = await fetchData(`admin/service-step/${step.id}/condition`);
      if (res.status == 200 && res2.status == 200) {
        var temp = service.steps;
        var referral = res2.data.data.filter((a) => {
          return a.parameter == "referral_by";
        });
        if (referral.length == 0) {
          referral[0] = {};
        }
        var age = res2.data.data.filter((a) => {
          return a.parameter == "age";
        });
        var block = res.data.data.map((b) => {
          return {
            ...b,
            blockingStepId: service.steps.filter((s) => {
              return s.id == b.blockingStepId;
            })[0].title,
          };
        });
        temp[i] = {
          ...temp[i],
          blocks: block,
          condition: { referral: referral[0], age: age },
        };
        setService({ ...service, steps: temp });
      } else {
        setLoading(false);
        Modal.error({ title: res });
        return false;
      }
    } else if (!step.blocks) {
      var temp = service.steps;
      temp[i] = { ...temp[i], blocks: [] };
      setService({ ...service, steps: temp });
    }
    setLoading(false);
    return true;
  };
  useEffect(() => {
    if (privilage.indexOf(access.serviceManegment.getCondition) > -1)
      getCondition(step, i);
  }, []);
  return (
    <>
      <div
        id={"step" + i}
        style={{ marginBottom: "-20px", marginTop: "-30px" }}
        className={`d-flex ${isMobile ? "flex-column px-2" : "flex-row"} gap-4`}
      >
        <div
          style={
            isMobile
              ? { width: "100%", marginTop: "-20px", marginLeft: "-15px" }
              : { width: "5%" }
          }
          className="d-flex flex-row"
        >
          <div
            style={{ marginLeft: "15px" }}
            className="d-flex flex-column align-items-center gap-3"
          >
            <span
              style={{
                color: " rgba(46, 108, 223, 1)",
                fontSize: "16px",
                fontWeight: "700",
                marginTop: "7px",
              }}
            >
              {i + 1}
            </span>
            {step.hide ? (
              <svg
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={isMobile ? { marginTop: "7px" } : {}}
              >
                <path
                  d="M11.9243 3.0305C11.3436 2.27283 9.31159 0 6.00472 0C2.69786 0 0.678397 2.27287 0.0851807 3.0305C-0.0283936 3.1694 -0.0283936 3.37145 0.0851807 3.51035C0.66582 4.26802 2.69786 6.54084 6.00472 6.54084C9.31159 6.54084 11.331 4.26797 11.9243 3.51035C12.0252 3.37145 12.0252 3.16939 11.9243 3.0305ZM6.00472 5.70744C4.65416 5.70744 3.56875 4.62151 3.56875 3.27041C3.56875 1.91927 4.65421 0.833386 6.00472 0.833386C7.35528 0.833386 8.44069 1.91931 8.44069 3.27041C8.44069 4.62155 7.35532 5.70744 6.00472 5.70744Z"
                  fill="#4C4C4C"
                />
                <path
                  d="M7.80367 3.27322C7.80367 4.26793 6.99765 5.07426 6.0034 5.07426C5.00914 5.07426 4.20312 4.2679 4.20312 3.27322C4.20312 2.27853 5.00914 1.47217 6.0034 1.47217C6.99765 1.47217 7.80367 2.27853 7.80367 3.27322Z"
                  fill="#4C4C4C"
                />
              </svg>
            ) : (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={isMobile ? { marginTop: "7px" } : {}}
              >
                <path
                  d="M12.9246 7.03601C12.5834 6.58475 11.7244 5.61952 10.3978 4.89246L9.15964 6.12088C9.34912 6.45935 9.45021 6.86043 9.45021 7.27411C9.45021 8.61542 8.36368 9.6934 7.01183 9.6934C6.59491 9.6934 6.19066 9.59311 5.8495 9.40511L5.01562 10.2325C5.62204 10.4079 6.27903 10.5207 7.01181 10.5207C10.3219 10.5207 12.3434 8.26441 12.9372 7.5123C13.0256 7.37441 13.0256 7.17392 12.9245 7.03604L12.9246 7.03601Z"
                  fill="#4C4C4C"
                />
                <path
                  d="M7.0008 9.02904C7.97365 9.02904 8.76953 8.23931 8.76953 7.27417C8.76953 7.04858 8.71903 6.8229 8.64315 6.62231L6.34375 8.90371C6.546 8.97891 6.77347 9.02901 7.00083 9.02901L7.0008 9.02904Z"
                  fill="#4C4C4C"
                />
                <path
                  d="M11.66 2.12224C11.4957 1.95925 11.2305 1.95925 11.0662 2.12224L8.89314 4.27829C8.32463 4.1153 7.69293 4.015 7.01065 4.015C3.70052 4.015 1.67907 6.27133 1.08526 7.02344C0.971578 7.16133 0.971578 7.36191 1.08526 7.4998C1.42641 7.93857 2.2476 8.87871 3.52364 9.59317L1.818 11.2855C1.65373 11.4484 1.65373 11.7116 1.818 11.8746C1.90648 11.9624 2.00747 12 2.12124 12C2.23492 12 2.336 11.9624 2.42448 11.8746L11.6726 2.69888C11.8243 2.56099 11.8243 2.28522 11.66 2.12231L11.66 2.12224ZM4.55966 7.27419C4.55966 5.93288 5.6588 4.8549 6.99803 4.8549C7.37708 4.8549 7.74342 4.94269 8.07198 5.10558L7.55396 5.61955C7.37708 5.56944 7.2002 5.53176 6.99803 5.53176C6.02519 5.53176 5.22931 6.3215 5.22931 7.28664C5.22931 7.47463 5.2672 7.66271 5.31779 7.8382L4.79977 8.35217C4.6481 8.0137 4.5597 7.65013 4.5597 7.27415L4.55966 7.27419Z"
                  fill="#4C4C4C"
                />
              </svg>
            )}
          </div>
        </div>
        <div
          style={step.hide ? { opacity: "25%" } : {}}
          className="d-flex flex-column col-lg-5 col-12"
        >
          <div className=" d-flex flex-column gap-2 col-12">
            <div className="d-flex flex-row gap-1 col-12">
              <h4 className="col-lg-5 col-6">Title:</h4>
              <h4 style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                {step.title}
              </h4>
            </div>
            <div className="d-flex flex-row gap-1 col-12">
              <h4 className="col-lg-5 col-6">Type:</h4>
              <h4 style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                {step.type}
              </h4>
            </div>
            {step.type != "information" &&
              step.type != "payment" &&
              step.type != "document" && (
                <div className="d-flex flex-row gap-1 col-12">
                  <h4 className="col-lg-5 col-6">Event:</h4>
                  <h4 style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                    {events[step.type].filter((x) => {
                      return x.id == step.modelId;
                    })[0]
                      ? events[step.type].filter((x) => {
                          return x.id == step.modelId;
                        })[0].title
                      : null}
                  </h4>
                </div>
              )}
            {step.type == "payment" && (
              <div className="d-flex flex-row gap-1 col-12">
                <h4 className="col-lg-5 col-6">Price:</h4>
                <h4 style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                  {step.price}
                </h4>
              </div>
            )}
            <div className="d-flex flex-row gap-1 col-12">
              <h4 className="col-lg-5 col-6">Description:</h4>
              <h4 style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                {step.description ?? " ---"}
              </h4>
            </div>
            <div className="d-flex flex-row gap-1 col-12">
              <h4 className="col-lg-5 col-6">Send Notification by:</h4>
              <h4 style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                phone
              </h4>
            </div>
          </div>
          <div
            style={isMobile ? { marginTop: "30px" } : {}}
            className="d-flex flex-row gap-1 col-12"
          >
            {loading ? (
              <Spin />
            ) : (
              privilage.indexOf(access.serviceManegment.indexBlock) > -1 && (
                <div className=" d-flex flex-column gap-2 col-12">
                  {service.steps[i].blocks
                    ? service.steps[i].blocks.length > 0 && (
                        <div className="service-view-step d-flex flex-row col-12 ">
                          <h4
                            className="col-lg-5 col-4"
                            style={{ color: "#2E6CDF" }}
                          >
                            {" "}
                            Blocked by:
                          </h4>
                          <div className="d-flex flex-column col-lg-7 col-8">
                            {service.steps[i].blocks.map((b) => {
                              return (
                                <h4 style={{ lineHeight: "20px" }}>
                                  Step
                                  <strong className="mx-1">
                                    {b.blockingStepId}
                                  </strong>{" "}
                                  is{" "}
                                  <strong className="mx-1">{b.status}</strong>
                                </h4>
                              );
                            })}
                          </div>
                        </div>
                      )
                    : ""}
                  {service.steps[i].condition
                    ? service.steps[i].condition.age.length > 0 && (
                        <div className="service-view-step d-flex flex-row col-12">
                          <h4
                            className="col-lg-5 col-4"
                            style={{ color: "#2E6CDF" }}
                          >
                            {" "}
                            Age:
                          </h4>
                          <div className="d-flex flex-column col-lg-7 col-8">
                            {service.steps[i].condition.age.map((b) => {
                              return (
                                <h4>
                                  {" "}
                                  {b.operator} {b.value} month(s)
                                </h4>
                              );
                            })}
                          </div>
                        </div>
                      )
                    : ""}
                  {service.steps[i].condition
                    ? service.steps[i].condition.referral.value && (
                        <div className=" service-view-step d-flex flex-row col-12 ">
                          <h4
                            className="col-lg-5 col-4"
                            style={{ color: "#2E6CDF" }}
                          >
                            {" "}
                            Referral:
                          </h4>
                          <div className="d-flex flex-column col-lg-7 col-8">
                            <h4>
                              {" "}
                              {service.steps[i].condition.referral.value}{" "}
                            </h4>
                          </div>
                        </div>
                      )
                    : ""}
                </div>
              )
            )}
          </div>
        </div>
        <div
          style={
            step.hide
              ? { fontSize: "16px", fontWeight: "700", opacity: "25%" }
              : { fontSize: "16px", fontWeight: "700" }
          }
          className="service-view-step d-flex flex-column gap-2"
        >
          <h4>Starts at {step.startDays} after activate.</h4>
          <h4>Expires at {step.expireDays} after starting day.</h4>
          <h4>
            Requires Admin’s Approval{" "}
            {step.needAdminApproved ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <g clip-path="url(#clip0_4199_128481)">
                  <path
                    d="M7.5 15.5C3.35788 15.5 0 12.1421 0 8.00005C0 3.85797 3.35792 0.500046 7.5 0.500046C11.6421 0.500046 15 3.85797 15 8.00005C15 12.1421 11.6421 15.5 7.5 15.5ZM4.70352 7.81781C4.38085 7.51307 3.87226 7.52764 3.56752 7.85013C3.26278 8.17281 3.27736 8.6814 3.60003 8.98613L6.01065 11.263C6.35538 11.5885 6.90563 11.5465 7.19673 11.172L10.9468 6.35059C11.2192 6.00027 11.1561 5.49541 10.8058 5.22282C10.4554 4.95041 9.95058 5.01356 9.67818 5.36389L6.47121 9.48712L4.70352 7.81781Z"
                    fill="#248C00"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4199_128481">
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <g clip-path="url(#clip0_4199_128643)">
                  <path
                    d="M7.5 15.5001C3.35788 15.5001 0 12.1421 0 8.00006C0 3.85799 3.35792 0.500061 7.5 0.500061C11.6421 0.500061 15 3.85799 15 8.00006C15 12.1421 11.6421 15.5001 7.5 15.5001ZM4.70352 7.81782C4.38085 7.51308 3.87226 7.52766 3.56752 7.85015C3.26278 8.17282 3.27736 8.68141 3.60003 8.98615L6.01065 11.263C6.35538 11.5885 6.90563 11.5465 7.19673 11.172L10.9468 6.35061C11.2192 6.00028 11.1561 5.49543 10.8058 5.22284C10.4554 4.95042 9.95058 5.01357 9.67818 5.3639L6.47121 9.48713L4.70352 7.81782Z"
                    fill="#D1D1D1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4199_128643">
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            )}
          </h4>
          <h4>
            Requires User’s Approval{" "}
            {step.needUserApproved ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <g clip-path="url(#clip0_4199_128481)">
                  <path
                    d="M7.5 15.5C3.35788 15.5 0 12.1421 0 8.00005C0 3.85797 3.35792 0.500046 7.5 0.500046C11.6421 0.500046 15 3.85797 15 8.00005C15 12.1421 11.6421 15.5 7.5 15.5ZM4.70352 7.81781C4.38085 7.51307 3.87226 7.52764 3.56752 7.85013C3.26278 8.17281 3.27736 8.6814 3.60003 8.98613L6.01065 11.263C6.35538 11.5885 6.90563 11.5465 7.19673 11.172L10.9468 6.35059C11.2192 6.00027 11.1561 5.49541 10.8058 5.22282C10.4554 4.95041 9.95058 5.01356 9.67818 5.36389L6.47121 9.48712L4.70352 7.81781Z"
                    fill="#248C00"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4199_128481">
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <g clip-path="url(#clip0_4199_128643)">
                  <path
                    d="M7.5 15.5001C3.35788 15.5001 0 12.1421 0 8.00006C0 3.85799 3.35792 0.500061 7.5 0.500061C11.6421 0.500061 15 3.85799 15 8.00006C15 12.1421 11.6421 15.5001 7.5 15.5001ZM4.70352 7.81782C4.38085 7.51308 3.87226 7.52766 3.56752 7.85015C3.26278 8.17282 3.27736 8.68141 3.60003 8.98615L6.01065 11.263C6.35538 11.5885 6.90563 11.5465 7.19673 11.172L10.9468 6.35061C11.2192 6.00028 11.1561 5.49543 10.8058 5.22284C10.4554 4.95042 9.95058 5.01357 9.67818 5.3639L6.47121 9.48713L4.70352 7.81782Z"
                    fill="#D1D1D1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4199_128643">
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            )}
          </h4>
        </div>
      </div>
      <div className="h-line" />
    </>
  );
};

export default ServiceView;
