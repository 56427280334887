import { useEffect, useState, useRef } from "react";
import { fetchData, postData, putData, deleteData } from "../../endpoints";
import "./styles.scss";
import { useMobile } from "../../hooks";
import "react-accessible-accordion/dist/fancy-example.css";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleFilled,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import {
  Button,
  Input,
  Space,
  Table,
  Modal,
  Form,
  InputNumber,
  Select,
  Tag,
} from "antd";
import FormPage from "./formPage";
import { useForm } from "antd/es/form/Form";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const FormsManegment = () => {
  const isMobile = useMobile();
  const [publicForms, setPublicForms] = useState([]);
  const [privetForms, setPrivetForms] = useState([]);
  const [signForms, setSignForms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [publicSelectedRowKeys, setPublicSelectedRowKeys] = useState([]);
  const [privetSelectedRowKeys, setPrivetSelectedRowKeys] = useState([]);
  const [signSelectedRowKeys, setSignSelectedRowKeys] = useState([]);
  const [patientId, setPatientId] = useState([]);
  const [currentForm, setCurrentForm] = useState({});
  const [addForm, setAddForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [assignForm, setAssignForm] = useState(false);
  const [patients, setPatients] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText1, setSearchText1] = useState("");
  const [searchedColumn1, setSearchedColumn1] = useState("");
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
  const [isPrivet, setIsPrivet] = useState(false);
  const [isSign, setIsSign] = useState(false);
  const [codes, setCodes] = useState([]);
  const [signCodes, setSignCodes] = useState([]);
  const searchInput = useRef(null);
  const [myForm] = useForm();
  const [serviceTypesarray, setServiceTypes] = useState([]);
  const [navMobileItem, setNavMobileItem] = useState(0);
  const getServiceTypes = async () => {
    var res = await fetchData("admin/service-type");
    if (res.status == 200) {
      var temp = [];
      res.data.data.map((s) => {
        temp = [...temp, s];
        if (s.children) {
          temp = temp.concat(s.children);
        }
      });
      setServiceTypes(temp);
    }
  };

  async function fetchForms() {
    setIsLoading(true);
    var a = [];
    let b = [];
    var res = await fetchData(
      `admin/form/?sortKey=${"priority"}&sortValue=${1}&perPage=${300}&page=${1}`
    );
    if (res.status === 200) {
      res.data.data.map((f, i) => {
        if (f.visibility == "private") {
          if (f.children == []) {
            delete f.children;
            a = [...a, { ...f, key: i }];
          } else {
            var temp = f.children;
            delete f.children;
            a = [...a, { ...f, key: i, myChildren: temp }];
          }
        } else {
          if (f.children == []) {
            delete f.children;
            b = [...b, { ...f, key: i }];
          } else {
            var temp = f.children;
            delete f.children;
            b = [...b, { ...f, key: i, myChildren: temp }];
          }
        }
      });
      setPublicForms(b);
      setPrivetForms(a);
      var res2 = await fetchData("admin/form/jotform/index");
      if (res2.status == 200) {
        setCodes(res2.data.data);
      }
    } else {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    var res2 = await fetchData("admin/sign-form");
    if (res2.status == 200) {
      setSignForms(
        res2.data.data.map((f, i) => {
          return { ...f, key: i };
        })
      );
      var res2 = await fetchData("admin/sign-form/box/templates");
      if (res2.status == 200) {
        setSignCodes(res2.data.data);
      }
    }
    setIsLoading(false);
  }
  async function getPatientsOfForms(form) {
    var res = await fetchData("admin/form-access/?formId=" + form.id);
    if (res.status == 200) {
      // var res2 = fetchData("admin/form-submission/?formId=" + form.id);
      // if (res2.data == 200) {
      //   setCurrentForm({
      //     ...form,
      //     patient: patients.filter((p) => {
      //       var temp = false;
      //       res.data.data.forEach((record) => {
      //         if (p.id == record.patientId) {
      //           temp = p;
      //         }
      //       });
      //       return temp !== false;
      //     }),
      //     link: res2.data.data,
      //   });
      // } else {
      setCurrentForm({
        ...form,
        patient: patients.filter((p) => {
          var temp = false;
          res.data.data.forEach((record) => {
            if (p.id == record.patientId) {
              temp = p;
            }
          });
          return temp !== false;
        }),
      });
      // }
    }
  }
  const getPatient = async () => {
    setIsLoading(true);
    var res = await fetchData(`admin/patient`);
    if (res.status === 200) {
      setPatients(
        res.data.data.filter((u) => {
          return !u.deletedAt;
        })
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchForms();
    if (privilage.indexOf(access.patientManegment.indexPatient) > -1) {
      getPatient();
      getServiceTypes();
    }
  }, []);

  const { confirm } = Modal;

  const showDeleteConfirm = async (arr) => {
    confirm({
      title: "Are you sure you want to remove these forms?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeGrupe(arr);
      },
      onCancel() {},
    });
  };
  const removeForm = async (id) => {
    setIsLoading(true);
    var domain = isSign ? "admin/sign-form/" : `admin/form/`;
    var res = await deleteData(domain + id);
    if (res.status === 200) {
      setIsLoading(false);
      return true;
    } else {
      setIsLoading(false);
      return false;
    }
  };
  const removeGrupe = async (listOfForms) => {
    let isOk = true;
    for (var i in listOfForms) {
      var res = await removeForm(listOfForms[i].id);
      if (!res) {
        isOk = false;
      }
    }
    setIsSign(false);
    if (isOk) {
      await fetchForms();
      setPublicSelectedRowKeys([]);
      setPrivetSelectedRowKeys([]);
      Modal.success({ title: "Forms have been successfully deleted." });
    } else {
      Modal.error({ title: "There was a problem, please try again." });
    }
  };
  const PublicrowSelection = {
    publicSelectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setPublicSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const PrivetrowSelection = {
    privetSelectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setPrivetSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const signrowSelection = {
    signSelectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSignSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: isMobile ? "55%" : "35%",
      onCell: (record) => {
        return {
          onClick: async () => {
            if (
              privilage.indexOf(access.formManegment.show) > -1 &&
              privilage.indexOf(access.patientManegment.indexPatient) > -1
            )
              await getPatientsOfForms(record);
            setIsOpen(true);
          },
        };
      },
      ...getColumnSearchProps("title"),
      render: (record) => <h2 className="name-table">{record}</h2>,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "30%",
      responsive: ["md"],
      ...getColumnSearchProps("code"),
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) => (
        <div
          style={{ marginRight: "15px" }}
          dir="rtl"
          className="d-flex flex-row gap-4"
        >
          {privilage.indexOf(access.formManegment.delete) > -1 && (
            <span
              style={{
                color: "#BC2323",

                cursor: "pointer",
              }}
              onClick={async () => {
                if (!u.visibility) {
                  setIsSign(true);
                }
                await showDeleteConfirm([u]);
              }}
            >
              Remove
            </span>
          )}

          {privilage.indexOf(access.formManegment.edit) > -1 && (
            <span
              style={{
                color: "black",

                cursor: "pointer",
              }}
              onClick={async () => {
                if (!u.visibility) {
                  setIsSign(true);
                }
                myForm.setFieldsValue(u);
                setCurrentForm(u);
                setEditForm(true);
              }}
            >
              Edit
            </span>
          )}
          {u.visibility &&
            privilage.indexOf(access.formManegment.assign) > -1 && (
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={async () => {
                  setCurrentForm(u);
                  setAssignForm(true);
                }}
              >
                Assign
              </span>
            )}
        </div>
      ),
    },
  ];

  return (
    <div
      dir="ltr"
      className={
        isMobile
          ? isOpen
            ? "d-flex flex-column w-100"
            : "d-flex flex-column w-100 px-3 py-5"
          : "d-flex flex-column w-100 px-5 py-5"
      }
      style={{ backgroundColor: "white", minHeight: "100vh" }}
    >
      {isOpen &&
        FormPage(
          setIsOpen,
          currentForm,
          setCurrentForm,
          isMobile,
          navMobileItem,
          setNavMobileItem
        )}
      {!isOpen && (
        <div className={`${isMobile ? "col-11 d-flex flex-column mx-1 " : ""}`}>
          <div
            className={`${
              isMobile
                ? "forms d-flex flex-column gap-3  w-100"
                : "forms d-flex flex-column gap-3 py-4 px-3 w-100"
            }`}
          >
            <h1
              style={
                isMobile
                  ? { fontSize: "18px", fontWeight: "700" }
                  : { fontSize: "24px", fontWeight: "700" }
              }
            >
              Forms
            </h1>
            <div>
              <h2>Public Forms</h2>
              <div className="line" />
              <div
                className="d-flex flex-row gap-3"
                style={{
                  justifyContent: "flex-end",
                  width: "95%",
                  fontWeight: "bold",
                  marginBottom: `${isMobile ? "0px" : "30px"}`,
                }}
              >
                {publicSelectedRowKeys.length > 0 && (
                  <>
                    <span
                      onClick={() => {
                        setPublicSelectedRowKeys([]);
                      }}
                    >
                      Cancel
                    </span>
                    {privilage.indexOf(access.formManegment.delete) > -1 && (
                      <span
                        onClick={async () => {
                          await showDeleteConfirm(
                            publicForms.filter((f) => {
                              let is = false;
                              publicSelectedRowKeys.forEach((k) => {
                                if (f.key == k) is = true;
                              });
                              return is;
                            })
                          );
                        }}
                        style={{ color: "#BC2323" }}
                      >
                        Remove
                      </span>
                    )}
                  </>
                )}
              </div>

              <Table
                // scroll={{ x: "auto" }}
                loading={isLoading}
                rowSelection={{
                  type: "checkbox",
                  ...PublicrowSelection,
                }}
                columns={columns}
                dataSource={publicForms.filter((f) => {
                  return !f.deletedAt;
                })}
                size="small"
                style={{ width: "100%" }}
                footer={() => {
                  if (privilage.indexOf(access.formManegment.add) > -1)
                    return (
                      <div
                        onClick={() => {
                          setCurrentForm({
                            title: "",
                            code: "",
                            status: "",
                            priority: "",
                            parentId: "",
                            description: "",
                          });
                          setIsPrivet(false);
                          setAddForm(true);
                        }}
                        className="d-flex fle-row gap-2 px-4 align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <PlusOutlined
                          style={{
                            color: "rgba(46, 108, 223, 1)",
                          }}
                        />
                        <h6
                          style={{
                            color: "rgba(46, 108, 223, 1)",
                            marginBottom: "0",
                          }}
                        >
                          Add Form
                        </h6>
                      </div>
                    );
                }}
                expandIcon={({ expanded, onExpand, record }) => {
                  return expanded ? (
                    <CaretUpOutlined
                      style={{ fontSize: "18px" }}
                      onClick={(e) => onExpand(record, e)}
                    />
                  ) : (
                    <CaretDownOutlined
                      style={{ fontSize: "18px" }}
                      onClick={(e) => onExpand(record, e)}
                    />
                  );
                }}
                expandable={{
                  expandedRowRender: (f) => {
                    var data = [];
                    f.myChildren.map((c) => {
                      if (c.children && c.children.length == 0) {
                        delete c.children;
                      }
                      data = [...data, { ...c, key: c.title }];
                    });
                    return (
                      <Table
                        // scroll={{ x: "auto" }}
                        loading={isLoading}
                        rowSelection={{
                          type: "checkbox",
                          ...PublicrowSelection,
                        }}
                        columns={columns}
                        dataSource={data.filter((f) => {
                          return !f.deletedAt;
                        })}
                        size="small"
                      />
                    );
                  },
                  rowExpandable: (record) =>
                    record.hasOwnProperty("myChildren"),
                }}
              />
            </div>
            <div
              style={isMobile ? { marginTop: "0px" } : { marginTop: "50px" }}
            >
              <h2>Private Forms</h2>
              <div className="line" />

              <div
                className="d-flex flex-row gap-3"
                style={{
                  justifyContent: "flex-end",
                  width: "95%",
                  fontWeight: "bold",
                  marginBottom: `${isMobile ? "0px" : "30px"}`,
                }}
              >
                {privetSelectedRowKeys.length > 0 && (
                  <>
                    <span
                      onClick={() => {
                        setPrivetSelectedRowKeys([]);
                      }}
                    >
                      Cancel
                    </span>
                    {privilage.indexOf(access.formManegment.delete) > -1 && (
                      <span
                        onClick={async () => {
                          await showDeleteConfirm(
                            privetForms.filter((f) => {
                              let is = false;
                              privetSelectedRowKeys.forEach((k) => {
                                if (f.key == k) is = true;
                              });
                              return is;
                            })
                          );
                        }}
                        style={{ color: "#BC2323" }}
                      >
                        Remove
                      </span>
                    )}
                  </>
                )}
              </div>

              <div className=" d-flex flex-column gap-3 ">
                <Table
                  // scroll={{ x: "auto" }}
                  loading={isLoading}
                  rowSelection={{
                    type: "checkbox",
                    ...PrivetrowSelection,
                  }}
                  columns={columns}
                  dataSource={privetForms.filter((f) => {
                    return !f.deletedAt;
                  })}
                  size="small"
                  style={{ width: "100%" }}
                  expandIcon={({ expanded, onExpand, record }) => {
                    return expanded ? (
                      <CaretUpOutlined
                        style={{ fontSize: "18px" }}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: "18px" }}
                        onClick={(e) => onExpand(record, e)}
                      />
                    );
                  }}
                  expandable={{
                    expandedRowRender: (f) => {
                      var data = [];
                      f.myChildren.map((c) => {
                        if (c.children && c.children.length == 0) {
                          delete c.children;
                        }
                        data = [...data, { ...c, key: c.title }];
                      });
                      return (
                        <Table
                          loading={isLoading}
                          rowSelection={{
                            type: "checkbox",
                            ...PrivetrowSelection,
                          }}
                          columns={columns}
                          dataSource={data.filter((f) => {
                            return !f.deletedAt;
                          })}
                          size="small"
                          expandable={false}
                        />
                      );
                    },
                    rowExpandable: (record) =>
                      record.hasOwnProperty("myChildren"),
                  }}
                  footer={() => {
                    if (privilage.indexOf(access.formManegment.add) > -1)
                      return (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCurrentForm({
                              title: "",
                              code: "",
                              status: "",
                              priority: "",
                              parentId: "",
                              description: "",
                            });
                            setIsPrivet(true);
                            setAddForm(true);
                          }}
                          className="d-flex fle-row gap-2 px-4 align-items-center"
                        >
                          <PlusOutlined
                            style={{
                              color: "rgba(46, 108, 223, 1)",
                            }}
                          />
                          <h6
                            style={{
                              color: "rgba(46, 108, 223, 1)",
                              marginBottom: "0",
                            }}
                          >
                            Add Form
                          </h6>
                        </div>
                      );
                  }}
                />
              </div>
            </div>
            <div
              style={isMobile ? { marginTop: "0px" } : { marginTop: "50px" }}
            >
              <h2>Sign Forms</h2>
              <div className="line" />

              <div
                className="d-flex flex-row gap-3"
                style={{
                  justifyContent: "flex-end",
                  width: "95%",
                  fontWeight: "bold",
                  marginBottom: `${isMobile ? "0px" : "30px"}`,
                }}
              >
                {signSelectedRowKeys.length > 0 && (
                  <>
                    <span
                      onClick={() => {
                        setSignSelectedRowKeys([]);
                      }}
                    >
                      Cancel
                    </span>
                    {privilage.indexOf(access.formManegment.delete) > -1 && (
                      <span
                        onClick={async () => {
                          await showDeleteConfirm(
                            signForms.filter((f) => {
                              let is = false;
                              signSelectedRowKeys.forEach((k) => {
                                if (f.key == k) is = true;
                              });
                              return is;
                            })
                          );
                        }}
                        style={{ color: "#BC2323" }}
                      >
                        Remove
                      </span>
                    )}
                  </>
                )}
              </div>

              <div className=" d-flex flex-column gap-3 ">
                <Table
                  scroll={{ x: "auto" }}
                  loading={isLoading}
                  rowSelection={{
                    type: "checkbox",
                    ...signrowSelection,
                  }}
                  columns={columns}
                  dataSource={signForms.filter((f) => {
                    return !f.deletedAt;
                  })}
                  size="small"
                  style={{ width: "100%" }}
                  footer={() => {
                    if (privilage.indexOf(access.formManegment.add) > -1)
                      return (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCurrentForm({
                              title: "",
                              code: "",
                              status: "",
                              priority: "",
                              parentId: "",
                              description: "",
                            });
                            setIsSign(true);
                            setAddForm(true);
                          }}
                          className="d-flex fle-row gap-2 px-4 align-items-center"
                        >
                          <PlusOutlined
                            style={{
                              color: "rgba(46, 108, 223, 1)",
                            }}
                          />
                          <h6
                            style={{
                              color: "rgba(46, 108, 223, 1)",
                              marginBottom: "0",
                            }}
                          >
                            Add Form
                          </h6>
                        </div>
                      );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        destroyOnClose={true}
        style={{ top: "5vh" }}
        open={addForm}
        confirmLoading={isLoading}
        okText={"Add"}
        icon={false}
        onOk={async () => {
          setIsLoading(true);
          if (!isSign) {
            let temp = {
              ...currentForm,
              status: "active",
              visibility: isPrivet ? "private" : "public",
            };
            if (!temp.priority) {
              temp = { ...temp, priority: 0 };
            }
            if (!temp.parentId) {
              delete temp.parentId;
            }
            var res = await postData("admin/form", temp);
            if (res.status === 201) {
              Modal.success({
                title: res.data.message,
              });
              fetchForms();
              setAddForm(false);
              setIsSign(false);
              setCurrentForm({});
              myForm.resetFields();
            } else {
              Modal.error({
                title: "Error",
                content: res,
              });
            }
          } else {
            var res = await postData("admin/sign-form", currentForm);
            if (res.status === 201) {
              Modal.success({
                title: res.data.message,
              });
              fetchForms();
              setAddForm(false);
              setIsSign(false);
              setCurrentForm({});
              myForm.resetFields();
            } else {
              Modal.error({
                title: "Error",
                content: res,
              });
            }
          }
          setIsLoading(false);
        }}
        onCancel={() => {
          myForm.resetFields();
          setIsSign(false);
          setAddForm(false);
        }}
      >
        {AddForm(
          currentForm,
          setCurrentForm,
          false,
          myForm,
          isPrivet ? privetForms : publicForms,
          isSign ? signCodes : codes,
          isSign
        )}
      </Modal>
      <Modal
        style={{ top: "5vh" }}
        open={editForm}
        confirmLoading={isLoading}
        okText={"Save"}
        icon={false}
        destroyOnClose={true}
        onOk={async () => {
          setIsLoading(true);
          if (!isSign) {
            var res = await putData(
              "admin/form/" + currentForm.id,
              currentForm
            );
            if (res.status === 200) {
              myForm.resetFields();
              Modal.success({
                title: res.data.message,
              });
              fetchForms();
              setEditForm(false);
              setIsSign(false);
              setCurrentForm({});
            } else {
              Modal.error({
                title: "Error",
                content: res,
              });
            }
          } else {
            var res = await putData(
              "admin/sign-form/" + currentForm.id,
              currentForm
            );
            if (res.status === 200) {
              myForm.resetFields();
              Modal.success({
                title: res.data.message,
              });
              fetchForms();
              setEditForm(false);
              setIsSign(false);
              setCurrentForm({});
            } else {
              Modal.error({
                title: "Error",
                content: res,
              });
            }
          }

          setIsLoading(false);
        }}
        onCancel={() => {
          myForm.resetFields();
          setIsSign(false);
          setCurrentForm({});
          setEditForm(false);
        }}
      >
        {AddForm(
          currentForm,
          setCurrentForm,
          true,
          myForm,
          isPrivet ? privetForms : publicForms,
          isSign ? signCodes : codes,
          isSign
        )}
      </Modal>
      <Modal
        destroyOnClose={true}
        style={{ top: "5vh" }}
        open={assignForm}
        confirmLoading={isLoading}
        okText={"Save"}
        icon={false}
        width={"80vw"}
        onOk={async () => {
          setIsLoading(true);
          let ok = true;
          patientId.forEach(async (id) => {
            var res = await putData(`admin/form/${currentForm.id}/share`, {
              patientId: id,
              level: "submit_and_edit",
            });
            if (res.status !== 200) {
              ok = res;
              Modal.error({
                title: "Error",
                content: res,
              });
            }
          });
          if (ok === true) {
            Modal.success({
              title: "Form assigned successfuly.",
            });
            fetchForms();
            setCurrentForm({});
            setAssignForm(false);
          }
          setIsLoading(false);
        }}
        onCancel={() => {
          setCurrentForm({});
          setAssignForm(false);
        }}
      >
        {AssignForm(
          patients,
          setPatientId,
          searchText1,
          setSearchText1,
          searchedColumn1,
          setSearchedColumn1,
          selectedRowKeys1,
          setSelectedRowKeys1,
          searchInput,
          isMobile,
          serviceTypesarray,
          getPatient,
          setPatients,
          isLoading,
          setIsLoading
        )}
      </Modal>
    </div>
  );
};

const AddForm = (
  form,
  setForm,
  isEdit = false,
  myform,
  privetForms = [],
  codes,
  isSign = false
) => {
  return (
    <div className="w-100 px-5 py-3 align-items-center text-align-center d-flex flex-column gap-3">
      <h1 style={{ fontSize: "35px", fontWeight: "700" }}>
        {isEdit ? "Edit Form" : "Add Form"}
      </h1>
      {!isEdit ? (
        <Form
          preserve={false}
          name="add form"
          autoComplete="off"
          form={myform}
          style={{ width: "100%" }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please enter a title!" }]}
          >
            <Input
              value={form.title}
              onChange={(e) => {
                setForm({ ...form, title: e.target.value });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: "Please enter a code!" }]}
          >
            <Select
              value={form.code}
              onChange={(e) => {
                setForm({ ...form, code: e });
              }}
            >
              {codes.map((c) => {
                return (
                  <Select.Option value={c.id}>
                    {isSign ? c.name : c.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {!isSign && (
            <Form.Item label="Parent ID" name="parentId">
              <Select
                value={form.parentId}
                onChange={(value) => {
                  setForm({ ...form, parentId: value });
                }}
                allowClear
              >
                {privetForms.map((f) => {
                  return <Select.Option value={f.id}> {f.title}</Select.Option>;
                })}
              </Select>
            </Form.Item>
          )}
          {form.parentId && (
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true, message: "Please enter a priority!" }]}
            >
              <InputNumber
                value={form.priority}
                onChange={(e) => {
                  setForm({ ...form, priority: e });
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: !form.parentId,
                message: "Please enter a description!",
              },
            ]}
          >
            <Input.TextArea
              value={form.description}
              onChange={(e) => {
                setForm({ ...form, description: e.target.value });
              }}
            />
          </Form.Item>
        </Form>
      ) : (
        <Form
          preserve={false}
          form={myform}
          name="edit form"
          autoComplete="off"
          style={{ width: "100%" }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            title: form.title,
            code: form.code,
            parentId: form.parentId,
            priority: form.priority,
            description: form.description,
            status: form.status,
          }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please enter a title!" }]}
          >
            <Input
              value={form.title}
              onChange={(e) => {
                setForm({ ...form, title: e.target.value });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: "Please enter a code!" }]}
          >
            <Select
              value={form.code}
              onChange={(e) => {
                setForm({ ...form, code: e });
              }}
            >
              {codes.map((c) => {
                return (
                  <Select.Option value={c.id}>
                    {isSign ? c.name : c.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {!isSign && (
            <>
              <Form.Item label="Parent ID" name="parentId">
                <Select
                  value={form.parentId}
                  onChange={(value) => {
                    setForm({ ...form, parentId: value });
                  }}
                  allowClear
                >
                  {privetForms.map((f) => {
                    return (
                      <Select.Option value={f.id}> {f.title}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Priority"
                name="priority"
                rules={[
                  { required: true, message: "Please enter a priority!" },
                ]}
              >
                <InputNumber
                  value={form.priority}
                  onChange={(e) => {
                    setForm({ ...form, priority: e });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true, message: "Please enter a status!!" }]}
              >
                <Select
                  onChange={(value) => {
                    setForm({ ...form, status: value });
                  }}
                  value={form.status}
                >
                  <Select.Option value="active"> active</Select.Option>
                  <Select.Option value="disabled"> disabled</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}

          <Form.Item label="Description" name="description">
            <Input.TextArea
              value={form.description}
              onChange={(e) => {
                setForm({ ...form, description: e.target.value });
              }}
            />
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

const AssignForm = (
  patient,
  setPatientId,
  searchText,
  setSearchText,
  searchedColumn,
  setSearchedColumn,
  selectedRowKeys,
  setSelectedRowKeys,
  searchInput,
  isMobile,
  serviceTypesarray,
  getPatient,
  setPatients,
  loading,
  setLoading
) => {
  const addGrupe = (array) => {
    var temp = [];
    array.map((a) => {
      temp = [...temp, a.id];
    });
    setPatientId(temp);
  };
  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      await getPatient();
    } else {
      let search = "";
      if (dataIndex == "name") search = "fullName";
      else search = "clinicalCode";
      var res = await fetchData(`admin/patient/?${search}=` + selectedKeys[0]);
      if (res.status == 200) {
        setPatients(
          res.data.data.filter((u) => {
            return !u.deletedAt;
          })
        );
      }
    }
    setLoading(false);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const filterServiceType = async (pagination, filters, sorter, extra) => {
    if (filters.serviceTypes.length > 0) {
      setLoading(true);
      var p = [];
      for (const s of filters.serviceTypes) {
        var res = await fetchData(`admin/patient/?serviceTypes=` + s);
        if (res.status == 200) {
          p = p.concat(
            res.data.data.filter((u) => {
              return !u.deletedAt;
            })
          );
        }
      }
      p = removeDuplicates(p);
      setPatients(p);
      setLoading(false);
    } else {
      await getPatient();
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearch(selectedKeys, confirm, dataIndex);
          }}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {}
            }
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  function isTher(value, record) {
    let is = false;
    record.serviceTypes.forEach((element) => {
      if (element.includes(value)) {
        is = true;
      }
    });
    return is;
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "35%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      width: "20%",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Service",
      dataIndex: "serviceTypes",
      key: "serviceTypes",
      responsive: ["md"],
      width: "35%",
      render: (serviceTypes, record) => (
        <div className="d-flex flex-column gap-2">
          {serviceTypes &&
            serviceTypes.map((s, i) => {
              let color = ["geekblue", "purple", "magenta"];
              return (
                <Tag color={color[i % 3]} key={s}>
                  {s.serviceType.title}
                </Tag>
              );
            })}
        </div>
      ),
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
      addGrupe(
        data.filter((u) => {
          let is;
          for (let i = 0; i < newSelectedRowKeys.length; i++) {
            if (u.key === newSelectedRowKeys[i]) {
              is = true;
              break;
            } else {
              is = false;
            }
          }
          return is;
        })
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const data = patient.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: p.createdAt.substr(0, 10),
    };
  });
  return (
    <div
      className={
        isMobile
          ? "py-5 d-flex flex-column gap-3"
          : "px-5 py-5 d-flex flex-column gap-3"
      }
      style={{ width: "70vw" }}
    >
      <div
        className="d-flex flex-row gap-3 w-100"
        style={{
          justifyContent: "flex-end",
          fontWeight: "bold",
        }}
      >
        {selectedRowKeys.length > 0 && (
          <>
            <span
              onClick={() => {
                setSelectedRowKeys([]);
              }}
            >
              Cancel
            </span>
          </>
        )}
      </div>
      <Table
        loading={loading}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
        size="small"
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default FormsManegment;
